import { LookupSelect } from 'components/Lookups/LookupSelect';
import React from 'react';
import { JeFrequencyLookupModel } from 'types/graphql';
import { JeFrequencyLookupWrapper } from 'components/Lookups/JeFrequencyLookups/JeFrequencyLookupWrapper';

interface IProps {
  includeDeleted?: boolean;
  selectedId?: number | null;
  onSelect: (item?: JeFrequencyLookupModel) => void;
}

export const JeFrequencySelect = (props: IProps): JSX.Element => {
  const handleOnSelect = (value: any) =>
    props.onSelect(value as JeFrequencyLookupModel);

  return (
    <JeFrequencyLookupWrapper includeDeleted={props.includeDeleted}>
      {({ queryDocument, queryVariables, extractLookups }) => (
        <LookupSelect
          extractLookupItems={extractLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          selectedId={props.selectedId}
          onSelect={handleOnSelect}
        />
      )}
    </JeFrequencyLookupWrapper>
  );
};
