import { subMonths } from 'date-fns';
import DefaultClient from 'apollo-boost';
import { CheckBlock } from 'components/Form/CheckBlock';
import { DatePicker } from 'components/Form/DatePicker';
import { IntegerInput } from 'components/Form/IntegerInput';
import {
  IMonthAndYearSelection,
  MonthAndYearSelect,
} from 'components/Form/MonthAndYearSelect';
import TextAreaInput from 'components/Form/TextAreaInput';
import TextInput from 'components/Form/TextInput';
import { JeFrequencySelect } from 'components/Lookups/JeFrequencyLookups/JeFrequencySelect';
import { JeTypeSelect } from 'components/Lookups/JeTypeLookups/JeTypeSelect';
import { KbsEntitySelect } from 'components/Lookups/KbsEntityLookups/KbsEntitySelect';
import { KbsGroupSelect } from 'components/Lookups/KbsGroupLookups/KbsGroupSelect';
import { UserSelect } from 'components/Lookups/UserLookups/UserSelect';
import { RequiredMarker } from 'components/RequiredMarker/RequiredMarker';
import Column from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'components/Button';
import { nameof } from 'ts-simple-nameof';
import {
  CalculateNextScheduledDateDocument,
  CalculateNextScheduledDateMutation,
  CalculateNextScheduledDateMutationVariables,
  JeFrequencyLookupModel,
  LookupModel,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { ISelectionItem, Select } from 'components/Form/Select';

export interface IMasterChecklistItemDetail {
  title: string | null;
  description: string | null;
  journalId: string | null;
  kbsEntityLookupId: number | null;
  kbsGroupLookupId: number | null;
  jeTypeLookupId: number | null;
  comment: string | null;
  startMonth: number | null;
  startYear: number | null;
  retireMonth: number | null;
  retireYear: number | null;
  jeFrequencyLookupId: number | null;
  jeFrequencyPeriodOffset: number | null;
  jeFrequencyLookupPeriodModulus: number | null;
  jeFrequencyLookupPeriodOffset: number | null;
  numberOfOccurrences: number | null;
  dueDaysAfterClose: number | null;
  responsibleId: number | null;
  sendReminder: boolean;
  ignoreFromMonth: number | null;
  ignoreFromYear: number | null;
  ignoreUntilMonth: number | null;
  ignoreUntilYear: number | null;
  lastExecutedDate: Date | null;
}

interface IProps {
  detail: IMasterChecklistItemDetail;
  onChange: (detail: IMasterChecklistItemDetail) => void;
  onSave: (
    applyChangesToIncompleteJournalEntriesInOpenPeriods: boolean,
  ) => Promise<void>;
  onCancel: () => void;
}

export const MasterChecklistItemDetail = (props: IProps): JSX.Element => {
  const client = useRef<DefaultClient<unknown>>(useApolloClient().client);

  const [nextScheduledDate, setNextScheduledDate] = useState<Date | null>(null);

  const [frequencyPeriodOffsets, setFrequencyPeriodOffsets] = useState<
    ISelectionItem[]
  >([]);

  const populateFrequencyPeriods = (
    periodModulus: number | null | undefined,
  ) => {
    setFrequencyPeriodOffsets(
      !periodModulus
        ? []
        : [...Array(periodModulus)].map((_, i) => ({
            id: i + 1,
            label: (i + 1).toString(),
          })),
    );
  };

  useEffect(() => {
    populateFrequencyPeriods(props.detail.jeFrequencyLookupPeriodModulus);
  }, []);

  const calculateNextScheduledDate = () => {
    const lastExecutedDate = props.detail.lastExecutedDate;
    const frequency = props.detail.jeFrequencyLookupId;
    const frequencyPeriodOffset = props.detail.jeFrequencyPeriodOffset;

    if (!!frequency && !!frequencyPeriodOffset) {
      client.current
        .mutate<
          CalculateNextScheduledDateMutation,
          CalculateNextScheduledDateMutationVariables
        >({
          mutation: CalculateNextScheduledDateDocument,
          variables: {
            frequency,
            frequencyPeriodOffset,
            lastExecutedDate: lastExecutedDate || subMonths(new Date(), 1),
          },
        })
        .then((result) => {
          setNextScheduledDate(
            new Date(result.data?.calculateNextScheduledDate),
          );
        });
    } else {
      setNextScheduledDate(null);
    }
  };

  useEffect(calculateNextScheduledDate, [
    props.detail.jeFrequencyLookupId,
    props.detail.jeFrequencyPeriodOffset,
    props.detail.lastExecutedDate,
  ]);

  const handleTextFieldChange =
    (fieldName: string) => (value: string | null) => {
      props.onChange({
        ...props.detail,
        [fieldName]: value,
      });
    };

  const handleIntegerFieldChange =
    (fieldName: string) => (value: number | null) => {
      props.onChange({
        ...props.detail,
        [fieldName]: value,
      });
    };

  const handleLookupFieldChange =
    (fieldName: string) => (value?: LookupModel) => {
      props.onChange({
        ...props.detail,
        [fieldName]: value?.id,
      });
    };

  const handleFrequencyChange = async (value?: JeFrequencyLookupModel) => {
    props.onChange({
      ...props.detail,
      jeFrequencyLookupId: value?.id ?? null,
      jeFrequencyPeriodOffset: value?.periodOffset ?? null,
      jeFrequencyLookupPeriodModulus: value?.periodModulus ?? null,
    });
    populateFrequencyPeriods(value?.periodModulus);
  };

  const handleFrequencyPeriodOffsetChange = async (value?: ISelectionItem) => {
    props.onChange({
      ...props.detail,
      jeFrequencyPeriodOffset: Number(value?.id),
    });
  };

  const handleMonthYearFieldsChange =
    (monthFieldName: string, yearFieldName: string) =>
    (value?: IMonthAndYearSelection) => {
      props.onChange({
        ...props.detail,
        [monthFieldName]: value?.month,
        [yearFieldName]: value?.year,
      });
    };

  const handleSendReminderCheck = (checked: boolean) => {
    props.onChange({
      ...props.detail,
      sendReminder: checked,
    });
  };

  const handleSave =
    (applyChangesToIncompleteJournalEntriesInOpenPeriods: boolean) => () => {
      props
        .onSave(applyChangesToIncompleteJournalEntriesInOpenPeriods)
        .catch(() => {
          // display of error message was handled in wrapper component
          // but we add the catch here to avoid error about unaught exception in browser
        });
    };

  return (
    <>
      <div className="row">
        <Column>
          <SectionHeaderRow>
            <Column>
              <h1>Main Information</h1>
            </Column>
          </SectionHeaderRow>
          <hr className="mt-0" />
        </Column>
        <Column>
          <SectionHeaderRow>
            <Column>
              <h1>Schedule and Information</h1>
            </Column>
          </SectionHeaderRow>
          <hr className="mt-0" />
        </Column>
      </div>

      <div className="row">
        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn width={5}>
              Title <RequiredMarker />
            </FormLabelColumn>
            <Column>
              <TextInput
                value={props.detail?.title || ''}
                maxLength={75}
                onChange={handleTextFieldChange(
                  nameof<IMasterChecklistItemDetail>((e) => e.title),
                )}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Description <RequiredMarker />
            </FormLabelColumn>
            <Column>
              <TextAreaInput
                value={props.detail?.description || ''}
                rows={3}
                maxLength={255}
                onChange={handleTextFieldChange(
                  nameof<IMasterChecklistItemDetail>((e) => e.description),
                )}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Journal ID <RequiredMarker />
            </FormLabelColumn>
            <Column>
              <TextInput
                value={props.detail?.journalId ?? ''}
                onChange={handleTextFieldChange(
                  nameof<IMasterChecklistItemDetail>((e) => e.journalId),
                )}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Entity <RequiredMarker />
            </FormLabelColumn>
            <Column>
              <div className="row">
                <Column width={6}>
                  <KbsEntitySelect
                    selectedId={props.detail.kbsEntityLookupId}
                    onSelect={handleLookupFieldChange(
                      nameof<IMasterChecklistItemDetail>(
                        (e) => e.kbsEntityLookupId,
                      ),
                    )}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Group <RequiredMarker />
            </FormLabelColumn>
            <Column>
              <div className="row">
                <Column width={6}>
                  <KbsGroupSelect
                    selectedId={props.detail.kbsGroupLookupId}
                    onSelect={handleLookupFieldChange(
                      nameof<IMasterChecklistItemDetail>(
                        (e) => e.kbsGroupLookupId,
                      ),
                    )}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Type of Entry <RequiredMarker />
            </FormLabelColumn>
            <Column>
              <div className="row">
                <Column width={6}>
                  <JeTypeSelect
                    selectedId={props.detail.jeTypeLookupId}
                    onSelect={handleLookupFieldChange(
                      nameof<IMasterChecklistItemDetail>(
                        (e) => e.jeTypeLookupId,
                      ),
                    )}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Comments</FormLabelColumn>
            <Column>
              <TextAreaInput
                value={props.detail?.comment || ''}
                rows={5}
                maxLength={2048}
                onChange={handleTextFieldChange(
                  nameof<IMasterChecklistItemDetail>((e) => e.comment),
                )}
              />
            </Column>
          </div>
        </FieldSetColumn>

        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn width={5}>Start Month</FormLabelColumn>
            <Column>
              <MonthAndYearSelect
                monthValue={props.detail.startMonth}
                yearValue={props.detail.startYear}
                onSelect={handleMonthYearFieldsChange(
                  nameof<IMasterChecklistItemDetail>((e) => e.startMonth),
                  nameof<IMasterChecklistItemDetail>((e) => e.startYear),
                )}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Retire In</FormLabelColumn>
            <Column>
              <MonthAndYearSelect
                monthValue={props.detail.retireMonth}
                yearValue={props.detail.retireYear}
                onSelect={handleMonthYearFieldsChange(
                  nameof<IMasterChecklistItemDetail>((e) => e.retireMonth),
                  nameof<IMasterChecklistItemDetail>((e) => e.retireYear),
                )}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Frequency <RequiredMarker />
            </FormLabelColumn>
            <Column>
              <div className="row">
                <Column width={6}>
                  <JeFrequencySelect
                    selectedId={props.detail.jeFrequencyLookupId}
                    onSelect={handleFrequencyChange}
                  />
                </Column>
                <Column width={6}>
                  {props.detail?.jeFrequencyLookupId &&
                    (props.detail?.jeFrequencyLookupPeriodModulus || 0) > 1 && (
                      <div className="row">
                        <FormLabelColumn width={'auto'}>
                          Period <RequiredMarker />
                        </FormLabelColumn>
                        <Column>
                          <Select
                            selectedId={props.detail.jeFrequencyPeriodOffset}
                            items={frequencyPeriodOffsets}
                            onSelect={handleFrequencyPeriodOffsetChange}
                          />
                        </Column>
                      </div>
                    )}
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Number of Occurrences <RequiredMarker />
            </FormLabelColumn>
            <Column>
              <div className="row">
                <Column width={6}>
                  <IntegerInput
                    value={props.detail?.numberOfOccurrences ?? null}
                    maximumValue={9}
                    minimumValue={0}
                    onChange={handleIntegerFieldChange(
                      nameof<IMasterChecklistItemDetail>(
                        (e) => e.numberOfOccurrences,
                      ),
                    )}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Due days after close <RequiredMarker />
            </FormLabelColumn>
            <Column>
              <div className="row">
                <Column width={6}>
                  <IntegerInput
                    value={props.detail?.dueDaysAfterClose ?? null}
                    maximumValue={99}
                    minimumValue={0}
                    onChange={handleIntegerFieldChange(
                      nameof<IMasterChecklistItemDetail>(
                        (e) => e.dueDaysAfterClose,
                      ),
                    )}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Responsible <RequiredMarker />
            </FormLabelColumn>
            <Column>
              <div className="row">
                <Column width={6}>
                  <UserSelect
                    selectedId={props.detail.responsibleId}
                    onSelect={handleLookupFieldChange(
                      nameof<IMasterChecklistItemDetail>(
                        (e) => e.responsibleId,
                      ),
                    )}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Send Reminder Email</FormLabelColumn>
            <Column>
              <div className="row">
                <Column width={6}>
                  <CheckBlock
                    checked={props.detail.sendReminder}
                    onCheckedChange={handleSendReminderCheck}
                    className="form-control"
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Ignore From (and including)
            </FormLabelColumn>
            <Column>
              <MonthAndYearSelect
                monthValue={props.detail.ignoreFromMonth}
                yearValue={props.detail.ignoreFromYear}
                onSelect={handleMonthYearFieldsChange(
                  nameof<IMasterChecklistItemDetail>((e) => e.ignoreFromMonth),
                  nameof<IMasterChecklistItemDetail>((e) => e.ignoreFromYear),
                )}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Ignore Until (and including)
            </FormLabelColumn>
            <Column>
              <MonthAndYearSelect
                monthValue={props.detail.ignoreUntilMonth}
                yearValue={props.detail.ignoreUntilYear}
                onSelect={handleMonthYearFieldsChange(
                  nameof<IMasterChecklistItemDetail>((e) => e.ignoreUntilMonth),
                  nameof<IMasterChecklistItemDetail>((e) => e.ignoreUntilYear),
                )}
              />
            </Column>
          </div>
        </FieldSetColumn>
      </div>

      <div className="row mt-5">
        <Column width={6}>
          <SectionHeaderRow>
            <Column>
              <h1>Execution Information</h1>
            </Column>
          </SectionHeaderRow>
          <hr className="mt-0" />
        </Column>
      </div>

      <div className="row mb-5">
        <FieldSetColumn width={6}>
          <div className="row">
            <FormLabelColumn width={5}>Last Executed Date</FormLabelColumn>
            <Column>
              <div className="row">
                <Column width={6}>
                  <DatePicker
                    value={props.detail.lastExecutedDate || null}
                    disabled={true}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Next Scheduled Date</FormLabelColumn>
            <Column>
              <div className="row">
                <Column width={6}>
                  <DatePicker disabled={true} value={nextScheduledDate} />
                </Column>
              </div>
            </Column>
          </div>
        </FieldSetColumn>
        <Column width={6} className="text-right mt-auto mb-auto">
          <div className="row">
            <Column>
              <Button variant="primary" onClick={handleSave(true)}>
                Save &amp; Apply
              </Button>
              <Button
                variant="primary"
                className="ml-3"
                onClick={handleSave(false)}
              >
                Save
              </Button>
              <Button
                variant="warning"
                className="ml-3"
                onClick={props.onCancel}
              >
                Cancel
              </Button>
            </Column>
          </div>
        </Column>
      </div>
    </>
  );
};
