import useModalAlerts from 'components/Alerts/useModalAlerts';
import { Button } from 'components/Button';
import { IChipAutosuggestOption } from 'components/ChipAutosuggest';
import { CheckBlock } from 'components/Form/CheckBlock';
import { IntegerInput } from 'components/Form/IntegerInput';
import {
  IMonthAndYearSelection,
  MonthAndYearSelect,
} from 'components/Form/MonthAndYearSelect';
import TextAreaInput from 'components/Form/TextAreaInput';
import TextInput from 'components/Form/TextInput';
import { JournalIdChipAutosuggest } from 'components/JournalIdChipAutosuggest';
import { JeFrequencySelect } from 'components/Lookups/JeFrequencyLookups/JeFrequencySelect';
import { JeTypeSelect } from 'components/Lookups/JeTypeLookups/JeTypeSelect';
import { KbsEntitySelect } from 'components/Lookups/KbsEntityLookups/KbsEntitySelect';
import { KbsGroupSelect } from 'components/Lookups/KbsGroupLookups/KbsGroupSelect';
import { UserSelect } from 'components/Lookups/UserLookups/UserSelect';
import { Column } from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import { FormLabelColumn } from 'layouts/components/Grid/FormLabelColumn';
import { SectionHeaderRow } from 'layouts/components/Grid/SectionHeaderRow';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BulkUpdateMasterChecklistItemsDocument,
  BulkUpdateMasterChecklistItemsMutation,
  BulkUpdateMasterChecklistItemsMutationVariables,
  JeFrequencyLookupModel,
  LookupMasterChecklistItemsForBulkUpdateDocument,
  LookupMasterChecklistItemsForBulkUpdateQuery,
  LookupMasterChecklistItemsForBulkUpdateQueryVariables,
  MasterChecklistItemForBulkUpdateFragment,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { CheckBoxWithInputRow } from './CheckBoxWithtInputRow';
import { ISelectionItem, Select } from 'components/Form/Select';
import { RequiredMarker } from 'components/RequiredMarker/RequiredMarker';

export const BulkUpdateDetail = (): JSX.Element => {
  const location = useLocation();

  const { client } = useApolloClient();
  const [modalAlert, setModalAlert] = useState<React.ReactNode>();
  const { success, confirm, warning, apolloError, error } =
    useModalAlerts(setModalAlert);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [titleChecked, setTitleChecked] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [descriptionChecked, setDescriptionChecked] = useState(false);
  const [description, setDescription] = useState<string | undefined>();
  const [entityChecked, setEntityChecked] = useState(false);
  const [entityId, setEntityId] = useState<number | undefined>();
  const [groupChecked, setGroupChecked] = useState(false);
  const [groupId, setGroupId] = useState<number | undefined>();
  const [typeOfEntryChecked, setTypeOfEntryChecked] = useState(false);
  const [typeOfEntryId, setTypeOfEntryId] = useState<number | undefined>();
  const [startDateChecked, setStartDateChecked] = useState(false);
  const [startDate, setStartDate] = useState<
    IMonthAndYearSelection | undefined
  >();
  const [retireDateChecked, setRetireDateChecked] = useState(false);
  const [retireDate, setRetireDate] = useState<
    IMonthAndYearSelection | undefined
  >();
  const [frequencyChecked, setFrequencyChecked] = useState(false);
  const [frequencyId, setFrequencyId] = useState<number | undefined>();
  const [frequencyPeriodOffset, setFrequencyPeriodOffset] = useState<
    number | undefined
  >();
  const [frequencyLookupPeriodModulus, setFrequencyLookupPeriodModulus] =
    useState<number | undefined>();
  const [numberOfOccurrencesChecked, setNumberOfOccurrencesChecked] =
    useState(false);
  const [numberOfOccurrences, setNumberOfOccurrences] = useState<
    number | undefined
  >();
  const [dueDaysAfterCloseChecked, setDueDaysAfterCloseChecked] =
    useState(false);
  const [dueDaysAfterClose, setDueDaysAfterClose] = useState<
    number | undefined
  >();
  const [responsibleUserChecked, setResponsibleUserChecked] = useState(false);
  const [responsibleUserId, setResponsibleUserId] = useState<
    number | undefined
  >();
  const [sendReminderChecked, setSendReminderChecked] = useState(false);
  const [sendReminder, setSendReminder] = useState<boolean | undefined>();
  const [ignoreFromChecked, setIgnoreFromChecked] = useState(false);
  const [ignoreFromDate, setIgnoreFromDate] = useState<
    IMonthAndYearSelection | undefined
  >();
  const [ignoreUntilChecked, setIgnoreUntilChecked] = useState(false);
  const [ignoreUntilDate, setIgnoreUntilDate] = useState<
    IMonthAndYearSelection | undefined
  >();
  const [commentsChecked, setCommentsChecked] = useState(false);
  const [comments, setComments] = useState<string | undefined>();

  const [frequencyPeriodOffsets, setFrequencyPeriodOffsets] = useState<
    ISelectionItem[]
  >([]);

  const populateFrequencyPeriods = (
    periodModulus: number | null | undefined,
  ) => {
    console.log({ periodModulus });
    setFrequencyPeriodOffsets(
      !periodModulus
        ? []
        : [...Array(periodModulus)].map((_, i) => ({
            id: i + 1,
            label: (i + 1).toString(),
          })),
    );
  };

  const initialize = () => {
    const { journalId } =
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }) ?? [];

    const selectedIdsFromRequest = (
      Array.isArray(journalId) ? journalId : [journalId]
    ).filter((id) => !!id) as string[];
    setSelectedIds(selectedIdsFromRequest);
    refreshInputsForSelectedItems(selectedIdsFromRequest, true);
  };

  const handleJournalIdSelectionChanged = (
    options: readonly IChipAutosuggestOption[],
  ) => {
    const selectedOptionIds = options.map((option) => option.label);
    setSelectedIds(selectedOptionIds);
    refreshInputsForSelectedItems(selectedOptionIds);
  };

  const handleFrequencyChange = async (value?: JeFrequencyLookupModel) => {
    setFrequencyId(value?.id || undefined);
    setFrequencyPeriodOffset(value?.periodOffset || undefined);
    setFrequencyLookupPeriodModulus(value?.periodModulus || undefined);

    populateFrequencyPeriods(value?.periodModulus);
  };

  const handleFrequencyPeriodOffsetChanged = (
    value?: ISelectionItem | undefined,
  ) => {
    setFrequencyPeriodOffset(Number(value?.id));
  };

  const handleSave =
    (applyChangesToIncompleteJournalEntriesInOpenPeriods: boolean) => () => {
      if (selectedIds.length === 0) {
        warning({
          message:
            "You must choose at least one Journal Entry for bulk changes. Use the drop-down list at the top of the screen to select one or more JE's.",
        });
        return;
      }

      if (!isSomethingChecked()) {
        warning({
          message:
            'You must select at least one item to bulk update. Check the boxes beside fields you wish to update.',
        });
        return;
      }

      const mutate = () => {
        client
          .mutate<
            BulkUpdateMasterChecklistItemsMutation,
            BulkUpdateMasterChecklistItemsMutationVariables
          >({
            mutation: BulkUpdateMasterChecklistItemsDocument,
            variables: {
              input: {
                comment: commentsChecked ? comments : undefined,
                title: titleChecked ? title : undefined,
                description: descriptionChecked ? description : undefined,
                dueDaysAfterClose: dueDaysAfterCloseChecked
                  ? dueDaysAfterClose
                  : undefined,
                ignoreFromMonth: ignoreFromChecked
                  ? ignoreFromDate?.month ?? null
                  : undefined,
                ignoreFromYear: ignoreFromChecked
                  ? ignoreFromDate?.year ?? null
                  : undefined,
                ignoreUntilMonth: ignoreUntilChecked
                  ? ignoreUntilDate?.month ?? null
                  : undefined,
                ignoreUntilYear: ignoreUntilChecked
                  ? ignoreUntilDate?.year ?? null
                  : undefined,
                jeFrequencyLookupId: frequencyChecked ? frequencyId : undefined,
                jeFrequencyPeriodOffset: frequencyChecked
                  ? frequencyPeriodOffset
                  : undefined,
                jeTypeLookupId: typeOfEntryChecked ? typeOfEntryId : undefined,
                journalIds: selectedIds,
                kbsEntityLookupId: entityChecked ? entityId : undefined,
                kbsGroupLookupId: groupChecked ? groupId : undefined,
                numberOfOccurrences: numberOfOccurrencesChecked
                  ? numberOfOccurrences
                  : undefined,
                responsibleUserId: responsibleUserChecked
                  ? responsibleUserId
                  : undefined,
                retireMonth: retireDateChecked
                  ? retireDate?.month ?? null
                  : undefined,
                retireYear: retireDateChecked
                  ? retireDate?.year ?? null
                  : undefined,
                sendReminder: sendReminderChecked ? sendReminder : undefined,
                startMonth: startDateChecked
                  ? startDate?.month ?? null
                  : undefined,
                startYear: startDateChecked
                  ? startDate?.year ?? null
                  : undefined,

                applyChangesToIncompleteJournalEntriesInOpenPeriods,
              },
            },
          })
          .then(() => {
            success({
              message: 'Items Updated',
              timeout: 1500,
            });
            refreshInputsForSelectedItems(undefined, true);
          })
          .catch((error) => {
            apolloError({ error });
          });
      };

      validateForSubmit().then(() => {
        if (applyChangesToIncompleteJournalEntriesInOpenPeriods) {
          confirm({
            message:
              'Changes will be applied to currently open periods.  Are you sure you would like to do this?',
            onConfirm: mutate,
          });
        } else {
          mutate();
        }
      });
    };

  const handleCancel = () => {
    initialize();
  };

  const uniqueItems = (item: any, index: number, self: any[]) => {
    return self.indexOf(item) === index;
  };

  const areAllTheSame = (
    items: MasterChecklistItemForBulkUpdateFragment[],
    valueExtractor: (item: MasterChecklistItemForBulkUpdateFragment) => any,
  ) => {
    const values = items.map(valueExtractor);
    const uniqueValues = values.filter(uniqueItems);
    return uniqueValues.length === 1;
  };

  const updateInputValue = (
    checked: boolean,
    stateSetter: (value: any) => void,
    items: MasterChecklistItemForBulkUpdateFragment[],
    valueExtractor: (item: MasterChecklistItemForBulkUpdateFragment) => any,
  ) => {
    //only update the input value if not checked.
    if (!checked) {
      if (areAllTheSame(items, valueExtractor)) {
        stateSetter(valueExtractor(items[0]));
      } else {
        stateSetter(undefined);
      }
    }
  };

  const updateMonthYearValue = (
    checked: boolean,
    stateSetter: (value?: IMonthAndYearSelection) => void,
    items: MasterChecklistItemForBulkUpdateFragment[],
    monthExtractor: (
      item: MasterChecklistItemForBulkUpdateFragment,
    ) => number | null | undefined,
    yearExtractor: (
      item: MasterChecklistItemForBulkUpdateFragment,
    ) => number | null | undefined,
  ) => {
    //only update the input value if not checked.
    if (!checked) {
      const monthAndYearExtractor = (
        item: MasterChecklistItemForBulkUpdateFragment,
      ): IMonthAndYearSelection => ({
        month: monthExtractor(item),
        year: yearExtractor(item),
      });

      if (
        areAllTheSame(items, (item) => {
          const monthAndYear = monthAndYearExtractor(item);
          return `${monthAndYear.month}-${monthAndYear.year}`;
        })
      ) {
        stateSetter(monthAndYearExtractor(items[0]));
      } else {
        stateSetter(undefined);
      }
    }
  };

  const handleToggleCheckbox =
    (checked: boolean, stateSetter: (value: boolean) => void) => () => {
      stateSetter(!checked);
    };

  const handleMonthYearFieldsChange =
    (setter: (date?: IMonthAndYearSelection) => void) =>
    (value?: IMonthAndYearSelection) => {
      setter(value);
    };

  const resetInputs = (
    items: MasterChecklistItemForBulkUpdateFragment[],
    clearCheckboxes?: boolean,
  ) => {
    if (clearCheckboxes) setCommentsChecked(false);
    updateInputValue(
      clearCheckboxes ? false : commentsChecked,
      setComments,
      items,
      (item) => item.comment,
    );

    if (clearCheckboxes) setTitleChecked(false);
    updateInputValue(
      clearCheckboxes ? false : titleChecked,
      setTitle,
      items,
      (item) => item.title,
    );

    if (clearCheckboxes) setDescriptionChecked(false);
    updateInputValue(
      clearCheckboxes ? false : descriptionChecked,
      setDescription,
      items,
      (item) => item.description,
    );

    if (clearCheckboxes) setEntityChecked(false);
    updateInputValue(
      clearCheckboxes ? false : entityChecked,
      setEntityId,
      items,
      (item) => item.kbsEntity.id,
    );

    if (clearCheckboxes) setGroupChecked(false);
    updateInputValue(
      clearCheckboxes ? false : groupChecked,
      setGroupId,
      items,
      (item) => item.kbsGroup.id,
    );

    if (clearCheckboxes) setTypeOfEntryChecked(false);
    updateInputValue(
      clearCheckboxes ? false : typeOfEntryChecked,
      setTypeOfEntryId,
      items,
      (item) => item.jeType.id,
    );

    if (clearCheckboxes) setStartDateChecked(false);
    updateMonthYearValue(
      clearCheckboxes ? false : startDateChecked,
      setStartDate,
      items,
      (item) => item.startMonth,
      (item) => item.startYear,
    );

    if (clearCheckboxes) setRetireDateChecked(false);
    updateMonthYearValue(
      clearCheckboxes ? false : retireDateChecked,
      setRetireDate,
      items,
      (item) => item.retireMonth,
      (item) => item.retireYear,
    );

    if (clearCheckboxes) setFrequencyChecked(false);
    updateInputValue(
      clearCheckboxes ? false : frequencyChecked,
      setFrequencyId,
      items,
      (item) => item.jeFrequency.id,
    );
    updateInputValue(
      clearCheckboxes ? false : frequencyChecked,
      (value) => {
        populateFrequencyPeriods(value);
        setFrequencyLookupPeriodModulus(value);
      },
      items,
      (item) => item.jeFrequency.periodModulus,
    );
    updateInputValue(
      clearCheckboxes ? false : frequencyChecked,
      setFrequencyPeriodOffset,
      items,
      (item) => item.jeFrequencyPeriodOffset,
    );

    if (clearCheckboxes) setNumberOfOccurrencesChecked(false);
    updateInputValue(
      clearCheckboxes ? false : numberOfOccurrencesChecked,
      setNumberOfOccurrences,
      items,
      (item) => item.numberOfOccurrences,
    );

    if (clearCheckboxes) setDueDaysAfterCloseChecked(false);
    updateInputValue(
      clearCheckboxes ? false : dueDaysAfterCloseChecked,
      setDueDaysAfterClose,
      items,
      (item) => item.dueDaysAfterClose,
    );

    if (clearCheckboxes) setResponsibleUserChecked(false);
    updateInputValue(
      clearCheckboxes ? false : responsibleUserChecked,
      setResponsibleUserId,
      items,
      (item) => item.responsibleUser.id,
    );

    if (clearCheckboxes) setSendReminderChecked(false);
    updateInputValue(
      clearCheckboxes ? false : sendReminderChecked,
      setSendReminder,
      items,
      (item) => item.sendReminder,
    );

    if (clearCheckboxes) setIgnoreFromChecked(false);
    updateMonthYearValue(
      clearCheckboxes ? false : ignoreFromChecked,
      setIgnoreFromDate,
      items,
      (item) => item.ignoreFromMonth,
      (item) => item.ignoreFromYear,
    );

    if (clearCheckboxes) setIgnoreUntilChecked(false);
    updateMonthYearValue(
      clearCheckboxes ? false : ignoreUntilChecked,
      setIgnoreUntilDate,
      items,
      (item) => item.ignoreUntilMonth,
      (item) => item.ignoreUntilYear,
    );
  };

  const refreshInputsForSelectedItems = (
    journalEntryIds?: string[],
    clearCheckboxes?: boolean,
  ) => {
    const ids = journalEntryIds ?? selectedIds;

    if (ids.length) {
      client
        .query<
          LookupMasterChecklistItemsForBulkUpdateQuery,
          LookupMasterChecklistItemsForBulkUpdateQueryVariables
        >({
          query: LookupMasterChecklistItemsForBulkUpdateDocument,
          variables: {
            ids: journalEntryIds ?? selectedIds,
          },
          fetchPolicy: 'no-cache',
        })
        .then((value) => {
          resetInputs(value.data.masterChecklistItems.items, clearCheckboxes);
        });
    } else {
      resetInputs([], clearCheckboxes);
    }
  };

  useEffect(initialize, []);

  const isSomethingChecked = () => {
    return (
      titleChecked ||
      descriptionChecked ||
      entityChecked ||
      groupChecked ||
      typeOfEntryChecked ||
      startDateChecked ||
      retireDateChecked ||
      frequencyChecked ||
      numberOfOccurrencesChecked ||
      dueDaysAfterCloseChecked ||
      responsibleUserChecked ||
      sendReminderChecked ||
      ignoreFromChecked ||
      ignoreUntilChecked ||
      commentsChecked
    );
  };

  const validateForSubmit = () => {
    const requiredFieldsCheckedWithEmptyValue: string[] = [];
    const optionalFieldsCheckedWithEmptyValue: string[] = [];

    if (titleChecked && !title) {
      requiredFieldsCheckedWithEmptyValue.push('Title');
    }

    if (descriptionChecked && !description) {
      requiredFieldsCheckedWithEmptyValue.push('Description');
    }

    if (commentsChecked && !comments) {
      optionalFieldsCheckedWithEmptyValue.push('Comments');
    }

    if (entityChecked && !entityId) {
      requiredFieldsCheckedWithEmptyValue.push('Entity');
    }

    if (groupChecked && !groupId) {
      requiredFieldsCheckedWithEmptyValue.push('Group');
    }

    if (typeOfEntryChecked && !typeOfEntryId) {
      requiredFieldsCheckedWithEmptyValue.push('Type of Entry');
    }

    if (startDateChecked && !(startDate?.month && startDate?.year)) {
      optionalFieldsCheckedWithEmptyValue.push('Start Month');
    }

    if (retireDateChecked && !(retireDate?.month && retireDate?.year)) {
      optionalFieldsCheckedWithEmptyValue.push('Retire In');
    }

    if (frequencyChecked && !frequencyId) {
      requiredFieldsCheckedWithEmptyValue.push('Frequency');
    }

    if (frequencyChecked && !frequencyPeriodOffset) {
      requiredFieldsCheckedWithEmptyValue.push('Frequency Period');
    }

    if (numberOfOccurrencesChecked && numberOfOccurrences === undefined) {
      requiredFieldsCheckedWithEmptyValue.push('Number of Occurrences');
    }

    if (dueDaysAfterCloseChecked && dueDaysAfterClose === undefined) {
      requiredFieldsCheckedWithEmptyValue.push('Due Days After Close');
    }

    if (responsibleUserChecked && !responsibleUserId) {
      requiredFieldsCheckedWithEmptyValue.push('Responsible');
    }

    if (ignoreFromChecked && !(ignoreFromDate?.month && ignoreFromDate?.year)) {
      optionalFieldsCheckedWithEmptyValue.push('Ignore From');
    }

    if (
      ignoreUntilChecked &&
      !(ignoreUntilDate?.month && ignoreUntilDate?.year)
    ) {
      optionalFieldsCheckedWithEmptyValue.push('Ignore Until');
    }

    let promiseResolve = (value?: any) => {
      //stub
    };

    let promiseReject = () => {
      //stub
    };

    const promiseResult = new Promise((resolve, reject) => {
      promiseResolve = resolve;
      promiseReject = reject;
    });

    if (requiredFieldsCheckedWithEmptyValue.length) {
      error({
        label: 'Required fields missing values',
        message: (
          <>
            <p>
              The checkboxes for the following required fields have been
              checked, but you have not provided any values for the fields:
              <ul className="mt-3">
                {requiredFieldsCheckedWithEmptyValue.map((field) => (
                  <li>{field}</li>
                ))}
              </ul>
            </p>
          </>
        ),
      });

      promiseReject();
    } else if (optionalFieldsCheckedWithEmptyValue.length) {
      confirm({
        message: (
          <>
            <p>
              The checkboxes for the following optional fields have been
              checked, but you have not provided any values for the fields. Are
              you sure you wish to do this?
              <ul className="mt-3">
                {optionalFieldsCheckedWithEmptyValue.map((field) => (
                  <li>{field}</li>
                ))}
              </ul>
            </p>
          </>
        ),
        onCancel: promiseReject,
        onConfirm: promiseResolve,
        variant: 'warning',
      });
    } else {
      promiseResolve();
    }

    return promiseResult;
  };

  return (
    <>
      {modalAlert}
      <div className="row">
        <Column>
          <SectionHeaderRow>
            <Column>
              <h1>Journal Entry Master Bulk Update</h1>
            </Column>
          </SectionHeaderRow>
          <hr className="mt-0" />
        </Column>
      </div>

      <div className="row">
        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn width={4}>
              Journal Entry ID's To Update
            </FormLabelColumn>
            <Column>
              <JournalIdChipAutosuggest
                selectedOptions={selectedIds.map((id) => ({
                  id: id,
                  label: id,
                }))}
                onChange={handleJournalIdSelectionChanged}
              />
            </Column>
          </div>
        </FieldSetColumn>
      </div>

      <div className="row mt-5">
        <Column>
          <SectionHeaderRow>
            <Column>
              <h1>Main Information</h1>
            </Column>
          </SectionHeaderRow>
          <hr className="mt-0" />
        </Column>
        <Column>
          <SectionHeaderRow>
            <Column>
              <h1>Schedule and Assignment</h1>
            </Column>
          </SectionHeaderRow>
          <hr className="mt-0" />
        </Column>
      </div>

      <div className="row">
        <FieldSetColumn>
          <CheckBoxWithInputRow
            label="Title"
            isRequired={titleChecked}
            checked={titleChecked}
            onCheckedChange={handleToggleCheckbox(
              titleChecked,
              setTitleChecked,
            )}
          >
            <TextInput
              value={title ?? ''}
              maxLength={75}
              onChange={(value) => setTitle(value)}
            />
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Description"
            isRequired={descriptionChecked}
            rowClassName="mt-2"
            checked={descriptionChecked}
            onCheckedChange={handleToggleCheckbox(
              descriptionChecked,
              setDescriptionChecked,
            )}
          >
            <TextAreaInput
              value={description ?? ''}
              maxLength={255}
              onChange={(value) => setDescription(value)}
            />
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Entity"
            isRequired={entityChecked}
            rowClassName="mt-2"
            checked={entityChecked}
            onCheckedChange={handleToggleCheckbox(
              entityChecked,
              setEntityChecked,
            )}
          >
            <div className="row">
              <Column width={6}>
                <KbsEntitySelect
                  selectedId={entityId}
                  onSelect={(item) => {
                    setEntityId(item?.id);
                  }}
                />
              </Column>
            </div>
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Group"
            rowClassName="mt-2"
            isRequired={groupChecked}
            checked={groupChecked}
            onCheckedChange={handleToggleCheckbox(
              groupChecked,
              setGroupChecked,
            )}
          >
            <div className="row">
              <Column width={6}>
                <KbsGroupSelect
                  selectedId={groupId}
                  onSelect={(item) => {
                    setGroupId(item?.id);
                  }}
                />
              </Column>
            </div>
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Type of Entry"
            rowClassName="mt-2"
            isRequired={typeOfEntryChecked}
            checked={typeOfEntryChecked}
            onCheckedChange={handleToggleCheckbox(
              typeOfEntryChecked,
              setTypeOfEntryChecked,
            )}
          >
            <div className="row">
              <Column width={6}>
                <JeTypeSelect
                  selectedId={typeOfEntryId}
                  onSelect={(item) => {
                    setTypeOfEntryId(item?.id);
                  }}
                />
              </Column>
            </div>
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Comments"
            isRequired={false}
            rowClassName="mt-2"
            checked={commentsChecked}
            onCheckedChange={handleToggleCheckbox(
              commentsChecked,
              setCommentsChecked,
            )}
          >
            <TextAreaInput
              value={comments ?? ''}
              maxLength={2048}
              rows={4}
              onChange={(value) => setComments(value)}
            />
          </CheckBoxWithInputRow>
        </FieldSetColumn>
        <FieldSetColumn>
          <CheckBoxWithInputRow
            label="Start Month"
            isRequired={false}
            checked={startDateChecked}
            onCheckedChange={handleToggleCheckbox(
              startDateChecked,
              setStartDateChecked,
            )}
          >
            <MonthAndYearSelect
              monthValue={startDate?.month}
              yearValue={startDate?.year}
              onSelect={handleMonthYearFieldsChange(setStartDate)}
            />
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Retire In"
            isRequired={false}
            rowClassName="mt-2"
            checked={retireDateChecked}
            onCheckedChange={handleToggleCheckbox(
              retireDateChecked,
              setRetireDateChecked,
            )}
          >
            <MonthAndYearSelect
              monthValue={retireDate?.month}
              yearValue={retireDate?.year}
              onSelect={handleMonthYearFieldsChange(setRetireDate)}
            />
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Frequency"
            rowClassName="mt-2"
            isRequired={frequencyChecked}
            checked={frequencyChecked}
            onCheckedChange={handleToggleCheckbox(
              frequencyChecked,
              setFrequencyChecked,
            )}
          >
            <div className="row">
              <Column width={6}>
                <JeFrequencySelect
                  selectedId={frequencyId}
                  onSelect={handleFrequencyChange}
                />
              </Column>
              <Column width={6}>
                {frequencyId && (frequencyLookupPeriodModulus || 0) > 1 && (
                  <div className="row">
                    <FormLabelColumn width={'auto'}>
                      Period <RequiredMarker isRequired={frequencyChecked} />
                    </FormLabelColumn>
                    <Column>
                      <Select
                        selectedId={frequencyPeriodOffset}
                        items={frequencyPeriodOffsets}
                        onSelect={handleFrequencyPeriodOffsetChanged}
                      />
                    </Column>
                  </div>
                )}
              </Column>
            </div>
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Number of Occurrences"
            rowClassName="mt-2"
            isRequired={numberOfOccurrencesChecked}
            checked={numberOfOccurrencesChecked}
            onCheckedChange={handleToggleCheckbox(
              numberOfOccurrencesChecked,
              setNumberOfOccurrencesChecked,
            )}
          >
            <div className="row">
              <Column width={6}>
                <IntegerInput
                  value={numberOfOccurrences ?? null}
                  maximumValue={9}
                  minimumValue={0}
                  onChange={(value) => {
                    setNumberOfOccurrences(value ?? undefined);
                  }}
                />
              </Column>
            </div>
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Due Days After Close"
            rowClassName="mt-2"
            isRequired={dueDaysAfterCloseChecked}
            checked={dueDaysAfterCloseChecked}
            onCheckedChange={handleToggleCheckbox(
              dueDaysAfterCloseChecked,
              setDueDaysAfterCloseChecked,
            )}
          >
            <div className="row">
              <Column width={6}>
                <IntegerInput
                  value={dueDaysAfterClose ?? null}
                  maximumValue={99}
                  minimumValue={0}
                  onChange={(value) => {
                    setDueDaysAfterClose(value ?? undefined);
                  }}
                />
              </Column>
            </div>
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Responsible"
            rowClassName="mt-2"
            isRequired={responsibleUserChecked}
            checked={responsibleUserChecked}
            onCheckedChange={handleToggleCheckbox(
              responsibleUserChecked,
              setResponsibleUserChecked,
            )}
          >
            <div className="row">
              <Column width={6}>
                <UserSelect
                  selectedId={responsibleUserId}
                  onSelect={(item) => {
                    setResponsibleUserId(item?.id);
                  }}
                />
              </Column>
            </div>
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Send Reminder Email"
            rowClassName="mt-2"
            isRequired={sendReminderChecked}
            checked={sendReminderChecked}
            onCheckedChange={handleToggleCheckbox(
              sendReminderChecked,
              setSendReminderChecked,
            )}
          >
            <div className="row">
              <Column width={6}>
                <CheckBlock
                  checked={sendReminder}
                  onCheckedChange={handleToggleCheckbox(
                    sendReminder ?? false,
                    setSendReminder,
                  )}
                  className="form-control"
                />
              </Column>
            </div>
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Ignore From (and including)"
            isRequired={false}
            rowClassName="mt-2"
            checked={ignoreFromChecked}
            onCheckedChange={handleToggleCheckbox(
              ignoreFromChecked,
              setIgnoreFromChecked,
            )}
          >
            <MonthAndYearSelect
              monthValue={ignoreFromDate?.month}
              yearValue={ignoreFromDate?.year}
              onSelect={handleMonthYearFieldsChange(setIgnoreFromDate)}
            />
          </CheckBoxWithInputRow>
          <CheckBoxWithInputRow
            label="Ignore Until (and including)"
            isRequired={false}
            rowClassName="mt-2"
            checked={ignoreUntilChecked}
            onCheckedChange={handleToggleCheckbox(
              ignoreUntilChecked,
              setIgnoreUntilChecked,
            )}
          >
            <MonthAndYearSelect
              monthValue={ignoreUntilDate?.month}
              yearValue={ignoreUntilDate?.year}
              onSelect={handleMonthYearFieldsChange(setIgnoreUntilDate)}
            />
          </CheckBoxWithInputRow>
        </FieldSetColumn>
      </div>

      <div className="row mt-3">
        <Column></Column>
        <Column width={6} className="text-right mt-auto mb-auto">
          <div className="row">
            <Column>
              <Button variant="primary" onClick={handleSave(true)}>
                Save &amp; Apply
              </Button>
              <Button
                variant="primary"
                className="ml-3"
                onClick={handleSave(false)}
              >
                Save
              </Button>
              <Button variant="warning" className="ml-3" onClick={handleCancel}>
                Clear
              </Button>
            </Column>
          </div>
        </Column>
      </div>
    </>
  );
};
