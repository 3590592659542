import { DocumentNode } from 'graphql';
import React from 'react';
import {
  JeFrequencyLookupModel,
  JeFrequencyLookupsDocument,
  JeFrequencyLookupsQuery,
  JeFrequencyLookupsQueryVariables,
} from 'types/graphql';

interface IProps {
  includeDeleted?: boolean;
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: JeFrequencyLookupsQueryVariables;
    extractLookups: (
      query: JeFrequencyLookupsQuery,
    ) => JeFrequencyLookupModel[];
  }) => JSX.Element;
}

export const JeFrequencyLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: JeFrequencyLookupsDocument,
        queryVariables: { includeDeleted: props.includeDeleted },
        extractLookups: (query) => query.jeFrequencies,
      })}
    </>
  );
};
