import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AddMonthlyChecklistItemInput = {
  masterChecklistItemId?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type AddPeriodJeEmailReminderInput = {
  month?: Maybe<Scalars['Int']>;
  sendDate: Scalars['DateTime'];
  year?: Maybe<Scalars['Int']>;
};

export type BulkUpdateMasterChecklistItemsInput = {
  applyChangesToIncompleteJournalEntriesInOpenPeriods?: Maybe<
    Scalars['Boolean']
  >;
  comment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDaysAfterClose?: Maybe<Scalars['Int']>;
  ignoreFromMonth?: Maybe<Scalars['Int']>;
  ignoreFromYear?: Maybe<Scalars['Int']>;
  ignoreUntilMonth?: Maybe<Scalars['Int']>;
  ignoreUntilYear?: Maybe<Scalars['Int']>;
  jeFrequencyLookupId?: Maybe<Scalars['Int']>;
  jeFrequencyPeriodOffset?: Maybe<Scalars['Int']>;
  jeTypeLookupId?: Maybe<Scalars['Int']>;
  journalIds?: Maybe<Array<Scalars['String']>>;
  kbsEntityLookupId?: Maybe<Scalars['Int']>;
  kbsGroupLookupId?: Maybe<Scalars['Int']>;
  numberOfOccurrences?: Maybe<Scalars['Int']>;
  responsibleUserId?: Maybe<Scalars['Int']>;
  retireMonth?: Maybe<Scalars['Int']>;
  retireYear?: Maybe<Scalars['Int']>;
  sendReminder?: Maybe<Scalars['Boolean']>;
  startMonth?: Maybe<Scalars['Int']>;
  startYear?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type DateRange = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type InitiatePeriodInput = {
  closeDate?: Maybe<Scalars['DateTime']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type JeFrequencyLookupModel = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  periodModulus: Scalars['Int'];
  periodOffset: Scalars['Int'];
};

export type JournalEntries = {
  items: Array<JournalEntry>;
  totalItems: Scalars['Int'];
};

export type JournalEntry = {
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isUnplanned?: Maybe<Scalars['Boolean']>;
  journalId: Scalars['String'];
  monthlyChecklistItem: MonthlyChecklistItem;
  netsuiteTransactionNumber?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['DateTime']>;
  recordedDate?: Maybe<Scalars['DateTime']>;
};

export type JournalEntryCompletedByFilter = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  periodEndId?: Maybe<Scalars['Int']>;
  periodId?: Maybe<Scalars['Int']>;
  periodStartId?: Maybe<Scalars['Int']>;
};

export type JournalEntryCounts = {
  completedItems: Scalars['Int'];
  incompleteItems: Scalars['Int'];
  totalItems: Scalars['Int'];
};

export type JournalEntryFilter = {
  completedByNameIn?: Maybe<Array<Scalars['String']>>;
  dueDaysAfterCloseMax?: Maybe<Scalars['Int']>;
  dueDaysAfterCloseMin?: Maybe<Scalars['Int']>;
  kbsEntityLookupIdIn?: Maybe<Array<Scalars['Int']>>;
  kbsGroupLookupIdIn?: Maybe<Array<Scalars['Int']>>;
  periodEndId?: Maybe<Scalars['Int']>;
  periodId?: Maybe<Scalars['Int']>;
  periodStartId?: Maybe<Scalars['Int']>;
  responsibleUserIdIn?: Maybe<Array<Scalars['Int']>>;
  selectionType?: Maybe<JournalEntrySelectionType>;
};

export enum JournalEntrySelectionType {
  All = 'All',
  Completed = 'Completed',
  Incomplete = 'Incomplete',
}

export enum JournalEntrySortBy {
  CompletedBy = 'CompletedBy',
  DueDaysAfterClose = 'DueDaysAfterClose',
  Entity = 'Entity',
  Group = 'Group',
  Id = 'Id',
  JeType = 'JeType',
  JournalId = 'JournalId',
  Period = 'Period',
  PostedDate = 'PostedDate',
  RecordedDate = 'RecordedDate',
  Responsible = 'Responsible',
  Title = 'Title',
  TransactionNumber = 'TransactionNumber',
}

export type JournalEntrySortOptions = {
  sortBy?: Maybe<JournalEntrySortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type LookupFilter = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type LookupModel = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type MasterChecklistItem = {
  comment?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  dueDaysAfterClose: Scalars['Int'];
  id: Scalars['Int'];
  ignoreFromMonth?: Maybe<Scalars['Int']>;
  ignoreFromYear?: Maybe<Scalars['Int']>;
  ignoreUntilMonth?: Maybe<Scalars['Int']>;
  ignoreUntilYear?: Maybe<Scalars['Int']>;
  jeFrequency: JeFrequencyLookupModel;
  jeFrequencyPeriodOffset: Scalars['Int'];
  jeType: LookupModel;
  journalId: Scalars['String'];
  kbsEntity: LookupModel;
  kbsGroup: LookupModel;
  lastExecutedDate?: Maybe<Scalars['DateTime']>;
  numberOfOccurrences: Scalars['Int'];
  responsibleUser: LookupModel;
  retireMonth?: Maybe<Scalars['Int']>;
  retireYear?: Maybe<Scalars['Int']>;
  sendReminder: Scalars['Boolean'];
  startMonth?: Maybe<Scalars['Int']>;
  startYear?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type MasterChecklistItemFilter = {
  dueDaysAfterCloseMax?: Maybe<Scalars['Int']>;
  dueDaysAfterCloseMin?: Maybe<Scalars['Int']>;
  includeRetired?: Maybe<Scalars['Boolean']>;
  jeFrequencyLookupIdIn?: Maybe<Array<Scalars['Int']>>;
  jeTypeLookupIdIn?: Maybe<Array<Scalars['Int']>>;
  journalIdIn?: Maybe<Array<Scalars['String']>>;
  kbsEntityLookupIdIn?: Maybe<Array<Scalars['Int']>>;
  kbsGroupLookupIdIn?: Maybe<Array<Scalars['Int']>>;
  lastExecuted?: Maybe<DateRange>;
  responsibleUserIdIn?: Maybe<Array<Scalars['Int']>>;
  title?: Maybe<Scalars['String']>;
};

export type MasterChecklistItemInput = {
  applyChangesToIncompleteJournalEntriesInOpenPeriods?: Maybe<
    Scalars['Boolean']
  >;
  comment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDaysAfterClose?: Maybe<Scalars['Int']>;
  ignoreFromMonth?: Maybe<Scalars['Int']>;
  ignoreFromYear?: Maybe<Scalars['Int']>;
  ignoreUntilMonth?: Maybe<Scalars['Int']>;
  ignoreUntilYear?: Maybe<Scalars['Int']>;
  jeFrequencyLookupId?: Maybe<Scalars['Int']>;
  jeFrequencyPeriodOffset?: Maybe<Scalars['Int']>;
  jeTypeLookupId?: Maybe<Scalars['Int']>;
  journalId?: Maybe<Scalars['String']>;
  kbsEntityLookupId?: Maybe<Scalars['Int']>;
  kbsGroupLookupId?: Maybe<Scalars['Int']>;
  numberOfOccurrences?: Maybe<Scalars['Int']>;
  responsibleUserId?: Maybe<Scalars['Int']>;
  retireMonth?: Maybe<Scalars['Int']>;
  retireYear?: Maybe<Scalars['Int']>;
  sendReminder?: Maybe<Scalars['Boolean']>;
  startMonth?: Maybe<Scalars['Int']>;
  startYear?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export enum MasterChecklistItemSortBy {
  DueDaysAfterClose = 'DueDaysAfterClose',
  Entity = 'Entity',
  Frequency = 'Frequency',
  Group = 'Group',
  Id = 'Id',
  IgnoredUntil = 'IgnoredUntil',
  JeType = 'JeType',
  JournalId = 'JournalId',
  LastExecution = 'LastExecution',
  Responsible = 'Responsible',
  Title = 'Title',
}

export type MasterChecklistItemSortOptions = {
  sortBy?: Maybe<MasterChecklistItemSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type MasterChecklistItems = {
  items: Array<MasterChecklistItem>;
  totalItems: Scalars['Int'];
};

export type MonthlyChecklistItem = {
  comment?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  dueDaysAfterClose: Scalars['Int'];
  fallsInPeriod: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  jeFrequency: JeFrequencyLookupModel;
  jeFrequencyPeriodOffset: Scalars['Int'];
  jeType: LookupModel;
  journalId: Scalars['String'];
  kbsEntity: LookupModel;
  kbsGroup: LookupModel;
  masterChecklistItem: MasterChecklistItem;
  monthlyChecklistItemAction: MonthlyChecklistItemActionEnum;
  numberOfOccurrences: Scalars['Int'];
  period?: Maybe<Period>;
  responsibleUser: LookupModel;
  retireMonth?: Maybe<Scalars['Int']>;
  retireYear?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  wasAddedBySync?: Maybe<Scalars['Boolean']>;
};

export enum MonthlyChecklistItemActionEnum {
  Include = 'Include',
  Skip = 'Skip',
}

export type MonthlyChecklistItemFilter = {
  allChecklistItems?: Maybe<Scalars['Boolean']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export enum MonthlyChecklistItemSortBy {
  DueDaysAfterClose = 'DueDaysAfterClose',
  Entity = 'Entity',
  Frequency = 'Frequency',
  Group = 'Group',
  JournalId = 'JournalID',
  LastExecution = 'LastExecution',
  NumberOfOccurrences = 'NumberOfOccurrences',
  PartOfScheduledPeriod = 'PartOfScheduledPeriod',
  Responsible = 'Responsible',
  Title = 'Title',
  TypeOfEntry = 'TypeOfEntry',
}

export type MonthlyChecklistItemSortOptions = {
  sortBy?: Maybe<MonthlyChecklistItemSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type MonthlyChecklistItemView = {
  comment?: Maybe<Scalars['String']>;
  dueDaysAfterClose: Scalars['Int'];
  fallsInPeriod?: Maybe<Scalars['String']>;
  jeFrequency: JeFrequencyLookupModel;
  jeFrequencyPeriodOffset: Scalars['Int'];
  jeType: LookupModel;
  journalId: Scalars['String'];
  kbsEntity: LookupModel;
  kbsGroup: LookupModel;
  masterChecklistItem: MasterChecklistItem;
  monthlyChecklistItem?: Maybe<MonthlyChecklistItem>;
  monthlyChecklistItemAction: MonthlyChecklistItemActionEnum;
  monthlyChecklistItemId?: Maybe<Scalars['Int']>;
  numberOfOccurrences: Scalars['Int'];
  period?: Maybe<Period>;
  responsibleUser: LookupModel;
  title: Scalars['String'];
  wasAddedBySync?: Maybe<Scalars['Boolean']>;
};

export type MonthlyChecklistItemViewFilter = {
  includeUnscheduled?: Maybe<Scalars['Boolean']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export enum MonthlyChecklistItemViewSortBy {
  DueDaysAfterClose = 'DueDaysAfterClose',
  Entity = 'Entity',
  Frequency = 'Frequency',
  Group = 'Group',
  JournalId = 'JournalID',
  LastExecution = 'LastExecution',
  NumberOfOccurrences = 'NumberOfOccurrences',
  PartOfScheduledPeriod = 'PartOfScheduledPeriod',
  Responsible = 'Responsible',
  Title = 'Title',
  TypeOfEntry = 'TypeOfEntry',
}

export type MonthlyChecklistItemViewSortOptions = {
  sortBy?: Maybe<MonthlyChecklistItemViewSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type MonthlyChecklistItemViews = {
  items: Array<MonthlyChecklistItemView>;
  totalItems: Scalars['Int'];
};

export type MonthlyChecklistItems = {
  items: Array<MonthlyChecklistItem>;
  totalItems: Scalars['Int'];
};

export type Mutation = {
  addMonthlyChecklistItem: Scalars['Boolean'];
  addPeriodJeEmailReminder: PeriodJeEmailReminder;
  bulkUpdateMasterChecklistItems: Array<MasterChecklistItem>;
  calculateNextScheduledDate: Scalars['DateTime'];
  closePeriod?: Maybe<Period>;
  createMasterChecklistItem: MasterChecklistItem;
  deletePeriodJeEmailReminder: PeriodJeEmailReminder;
  initiatePeriod: Scalars['Boolean'];
  reopenPeriod?: Maybe<Period>;
  sendPeriodJeEmailReminderNow: Scalars['Boolean'];
  updateJournalEntry: Scalars['Boolean'];
  updateMasterChecklistItem: MasterChecklistItem;
  updateMonthlyChecklistItem: Scalars['Boolean'];
  updatePeriodCloseDate?: Maybe<Period>;
  updatePeriodJeEmailReminder: PeriodJeEmailReminder;
};

export type MutationAddMonthlyChecklistItemArgs = {
  input: AddMonthlyChecklistItemInput;
};

export type MutationAddPeriodJeEmailReminderArgs = {
  input: AddPeriodJeEmailReminderInput;
};

export type MutationBulkUpdateMasterChecklistItemsArgs = {
  input: BulkUpdateMasterChecklistItemsInput;
};

export type MutationCalculateNextScheduledDateArgs = {
  frequency: Scalars['Int'];
  frequencyPeriodOffset: Scalars['Int'];
  lastExecutedDate: Scalars['DateTime'];
};

export type MutationClosePeriodArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type MutationCreateMasterChecklistItemArgs = {
  input: MasterChecklistItemInput;
};

export type MutationDeletePeriodJeEmailReminderArgs = {
  id: Scalars['Int'];
};

export type MutationInitiatePeriodArgs = {
  input: InitiatePeriodInput;
};

export type MutationReopenPeriodArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type MutationSendPeriodJeEmailReminderNowArgs = {
  input: SendPeriodJeEmailReminderNowInput;
};

export type MutationUpdateJournalEntryArgs = {
  input: UpdateJournalEntryInput;
};

export type MutationUpdateMasterChecklistItemArgs = {
  id: Scalars['Int'];
  input: MasterChecklistItemInput;
};

export type MutationUpdateMonthlyChecklistItemArgs = {
  input: UpdateMonthlyChecklistItemInput;
};

export type MutationUpdatePeriodCloseDateArgs = {
  closeDate: Scalars['DateTime'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type MutationUpdatePeriodJeEmailReminderArgs = {
  input: UpdatePeriodJeEmailReminderInput;
};

export type PaginationOptions = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Period = {
  closeDate?: Maybe<Scalars['DateTime']>;
  closedDate?: Maybe<Scalars['DateTime']>;
  initiatedDate?: Maybe<Scalars['DateTime']>;
  month: Scalars['Int'];
  periodStatus: LookupModel;
  year: Scalars['Int'];
};

export type PeriodFilter = {
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type PeriodJeEmailReminder = {
  id: Scalars['Int'];
  sendDate: Scalars['DateTime'];
};

export type PeriodSelectionRange = {
  earliestMonth: Scalars['Int'];
  earliestYear: Scalars['Int'];
  latestMonth: Scalars['Int'];
  latestYear: Scalars['Int'];
};

export type Query = {
  completedByLookups: Array<StringLookupModel>;
  initiallySelectedPeriod: SelectedPeriodModel;
  jeFrequencies: Array<JeFrequencyLookupModel>;
  jeFrequency: JeFrequencyLookupModel;
  jeType: LookupModel;
  jeTypes: Array<LookupModel>;
  journalEntries: JournalEntries;
  journalEntry?: Maybe<JournalEntry>;
  journalEntryCounts: JournalEntryCounts;
  journalIdLookups: Array<LookupModel>;
  kbsEntities: Array<LookupModel>;
  kbsEntity: LookupModel;
  kbsGroup: LookupModel;
  kbsGroups: Array<LookupModel>;
  masterChecklistItem?: Maybe<MasterChecklistItem>;
  masterChecklistItems: MasterChecklistItems;
  monthlyChecklistItemViews: MonthlyChecklistItemViews;
  monthlyChecklistItems: MonthlyChecklistItems;
  period?: Maybe<Period>;
  periodJeEmailReminders: Array<PeriodJeEmailReminder>;
  periodLookups: Array<LookupModel>;
  periodSelectionRange: PeriodSelectionRange;
  skippedJournalEntries: JournalEntries;
  skippedJournalEntryCount: Scalars['Int'];
  userLookups: Array<LookupModel>;
};

export type QueryCompletedByLookupsArgs = {
  filter?: Maybe<JournalEntryCompletedByFilter>;
};

export type QueryJeFrequenciesArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryJeFrequencyArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryJeTypeArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryJeTypesArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryJournalEntriesArgs = {
  filter?: Maybe<JournalEntryFilter>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<JournalEntrySortOptions>;
};

export type QueryJournalEntryArgs = {
  id: Scalars['Int'];
};

export type QueryJournalEntryCountsArgs = {
  filter?: Maybe<JournalEntryFilter>;
};

export type QueryJournalIdLookupsArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryKbsEntitiesArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryKbsEntityArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryKbsGroupArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryKbsGroupsArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryMasterChecklistItemArgs = {
  id: Scalars['Int'];
};

export type QueryMasterChecklistItemsArgs = {
  filter?: Maybe<MasterChecklistItemFilter>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<MasterChecklistItemSortOptions>;
};

export type QueryMonthlyChecklistItemViewsArgs = {
  filter: MonthlyChecklistItemViewFilter;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<MonthlyChecklistItemViewSortOptions>;
};

export type QueryMonthlyChecklistItemsArgs = {
  filter: MonthlyChecklistItemFilter;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<MonthlyChecklistItemSortOptions>;
};

export type QueryPeriodArgs = {
  filter: PeriodFilter;
};

export type QueryPeriodJeEmailRemindersArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type QueryPeriodLookupsArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QuerySkippedJournalEntriesArgs = {
  filter?: Maybe<JournalEntryFilter>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<JournalEntrySortOptions>;
};

export type QuerySkippedJournalEntryCountArgs = {
  filter?: Maybe<JournalEntryFilter>;
};

export type QueryUserLookupsArgs = {
  filter?: Maybe<UserFilter>;
};

export type SelectedPeriodModel = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type SendPeriodJeEmailReminderNowInput = {
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StringLookupModel = {
  description?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type UpdateJournalEntryInput = {
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  journalId?: Maybe<Scalars['String']>;
};

export type UpdateMonthlyChecklistItemInput = {
  action?: Maybe<MonthlyChecklistItemActionEnum>;
  comment?: Maybe<Scalars['String']>;
  dueDaysAfterClose?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  journalId?: Maybe<Scalars['String']>;
  numberOfOccurrences?: Maybe<Scalars['Int']>;
};

export type UpdatePeriodJeEmailReminderInput = {
  id: Scalars['Int'];
  sendDate: Scalars['DateTime'];
};

export type UserFilter = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
  includeId?: Maybe<Scalars['Int']>;
  includeInactive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type JournalIdLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
}>;

export type JournalIdLookupsQuery = {
  journalIdLookups: Array<LookupModelFragment>;
};

export type CompletedByLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  periodId?: Maybe<Scalars['Int']>;
  periodStartId?: Maybe<Scalars['Int']>;
  periodEndId?: Maybe<Scalars['Int']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
}>;

export type CompletedByLookupsQuery = {
  completedByLookups: Array<StringLookupModelFragment>;
};

export type JeFrequencyLookupModelFragment = {
  id: number;
  name: string;
  description?: Maybe<string>;
  periodModulus: number;
  periodOffset: number;
  isDeleted?: Maybe<boolean>;
};

export type JeFrequencyLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
}>;

export type JeFrequencyLookupsQuery = {
  jeFrequencies: Array<JeFrequencyLookupModelFragment>;
};

export type JeTypeLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
}>;

export type JeTypeLookupsQuery = { jeTypes: Array<LookupModelFragment> };

export type KbsEntityLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
}>;

export type KbsEntityLookupsQuery = { kbsEntities: Array<LookupModelFragment> };

export type KbsGroupLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
}>;

export type KbsGroupLookupsQuery = { kbsGroups: Array<LookupModelFragment> };

export type PeriodLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
}>;

export type PeriodLookupsQuery = { periodLookups: Array<LookupModelFragment> };

export type UserLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  includeInactive?: Maybe<Scalars['Boolean']>;
  includeId?: Maybe<Scalars['Int']>;
}>;

export type UserLookupsQuery = { userLookups: Array<LookupModelFragment> };

export type LookupModelFragment = {
  id: number;
  name: string;
  description?: Maybe<string>;
  isDeleted?: Maybe<boolean>;
};

export type StringLookupModelFragment = {
  name: string;
  description?: Maybe<string>;
  isDeleted?: Maybe<boolean>;
};

export type JournalEntryTableDataItemFragment = {
  id: number;
  journalId: string;
  completedBy?: Maybe<string>;
  postedDate?: Maybe<any>;
  netsuiteTransactionNumber?: Maybe<string>;
  recordedDate?: Maybe<any>;
  comment?: Maybe<string>;
  isUnplanned?: Maybe<boolean>;
  monthlyChecklistItem: {
    id?: Maybe<number>;
    title: string;
    dueDaysAfterClose: number;
    retireYear?: Maybe<number>;
    retireMonth?: Maybe<number>;
    period?: Maybe<{ month: number; year: number; closedDate?: Maybe<any> }>;
    kbsEntity: { name: string };
    jeType: { name: string };
    kbsGroup: { name: string };
    responsibleUser: { name: string };
  };
};

export type MasterChecklistItemForBulkUpdateFragment = {
  id: number;
  title: string;
  description: string;
  journalId: string;
  startMonth?: Maybe<number>;
  startYear?: Maybe<number>;
  retireMonth?: Maybe<number>;
  retireYear?: Maybe<number>;
  jeFrequencyPeriodOffset: number;
  numberOfOccurrences: number;
  dueDaysAfterClose: number;
  sendReminder: boolean;
  ignoreFromMonth?: Maybe<number>;
  ignoreFromYear?: Maybe<number>;
  ignoreUntilMonth?: Maybe<number>;
  ignoreUntilYear?: Maybe<number>;
  comment?: Maybe<string>;
  kbsEntity: { id: number };
  kbsGroup: { id: number };
  jeType: { id: number };
  jeFrequency: { id: number; periodModulus: number; periodOffset: number };
  responsibleUser: { id: number };
};

export type LookupMasterChecklistItemsForBulkUpdateQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['String']>>;
}>;

export type LookupMasterChecklistItemsForBulkUpdateQuery = {
  masterChecklistItems: {
    items: Array<MasterChecklistItemForBulkUpdateFragment>;
  };
};

export type BulkUpdateMasterChecklistItemsMutationVariables = Exact<{
  input: BulkUpdateMasterChecklistItemsInput;
}>;

export type BulkUpdateMasterChecklistItemsMutation = {
  bulkUpdateMasterChecklistItems: Array<{ id: number }>;
};

export type MasterChecklistTableDataItemFragment = {
  id: number;
  ignoreUntilMonth?: Maybe<number>;
  ignoreUntilYear?: Maybe<number>;
  retireYear?: Maybe<number>;
  retireMonth?: Maybe<number>;
  journalId: string;
  jeFrequencyPeriodOffset: number;
  lastExecutedDate?: Maybe<any>;
  title: string;
  dueDaysAfterClose: number;
  responsibleUser: { name: string };
  jeType: { name: string };
  jeFrequency: { name: string; periodModulus: number };
  kbsEntity: { name: string };
  kbsGroup: { name: string };
};

export type MasterChecklistTableDataQueryVariables = Exact<{
  filter?: Maybe<MasterChecklistItemFilter>;
  sortOptions?: Maybe<MasterChecklistItemSortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type MasterChecklistTableDataQuery = {
  masterChecklistItems: {
    totalItems: number;
    items: Array<MasterChecklistTableDataItemFragment>;
  };
};

export type MasterChecklistItemDetailFragment = {
  id: number;
  comment?: Maybe<string>;
  description: string;
  dueDaysAfterClose: number;
  ignoreFromMonth?: Maybe<number>;
  ignoreFromYear?: Maybe<number>;
  ignoreUntilMonth?: Maybe<number>;
  ignoreUntilYear?: Maybe<number>;
  journalId: string;
  jeFrequencyPeriodOffset: number;
  lastExecutedDate?: Maybe<any>;
  numberOfOccurrences: number;
  sendReminder: boolean;
  startMonth?: Maybe<number>;
  startYear?: Maybe<number>;
  retireMonth?: Maybe<number>;
  retireYear?: Maybe<number>;
  title: string;
  responsibleUser: { id: number; name: string };
  jeType: { id: number };
  jeFrequency: { id: number; periodModulus: number; periodOffset: number };
  kbsEntity: { id: number };
  kbsGroup: { id: number };
};

export type MasterChecklistItemDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type MasterChecklistItemDetailQuery = {
  masterChecklistItem?: Maybe<MasterChecklistItemDetailFragment>;
};

export type CalculateNextScheduledDateMutationVariables = Exact<{
  lastExecutedDate: Scalars['DateTime'];
  frequency: Scalars['Int'];
  frequencyPeriodOffset: Scalars['Int'];
}>;

export type CalculateNextScheduledDateMutation = {
  calculateNextScheduledDate: any;
};

export type CreateMasterChecklistItemMutationVariables = Exact<{
  input: MasterChecklistItemInput;
}>;

export type CreateMasterChecklistItemMutation = {
  createMasterChecklistItem: { id: number };
};

export type UpdateMasterChecklistItemMutationVariables = Exact<{
  id: Scalars['Int'];
  input: MasterChecklistItemInput;
}>;

export type UpdateMasterChecklistItemMutation = {
  updateMasterChecklistItem: { id: number };
};

export type InitiatePeriodMutationVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
  closeDate: Scalars['DateTime'];
}>;

export type InitiatePeriodMutation = { initiatePeriod: boolean };

export type MonthlyChecklistItemViewFragment = {
  monthlyChecklistItemId?: Maybe<number>;
  monthlyChecklistItemAction: MonthlyChecklistItemActionEnum;
  fallsInPeriod?: Maybe<string>;
  dueDaysAfterClose: number;
  numberOfOccurrences: number;
  title: string;
  journalId: string;
  jeFrequencyPeriodOffset: number;
  wasAddedBySync?: Maybe<boolean>;
  comment?: Maybe<string>;
  kbsEntity: { id: number; name: string };
  jeType: { id: number; name: string };
  kbsGroup: { id: number; name: string };
  jeFrequency: { id: number; name: string; periodModulus: number };
  responsibleUser: { id: number; name: string };
  masterChecklistItem: { id: number; lastExecutedDate?: Maybe<any> };
  period?: Maybe<{ closedDate?: Maybe<any> }>;
};

export type FetchMonthlyChecklistItemsQueryVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
  allChecklistItems?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<MonthlyChecklistItemViewSortBy>;
  sortDirection?: Maybe<SortDirection>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;

export type FetchMonthlyChecklistItemsQuery = {
  monthlyChecklistItemViews: {
    totalItems: number;
    items: Array<MonthlyChecklistItemViewFragment>;
  };
};

export type UpdateMonthlyChecklistItemMutationVariables = Exact<{
  input: UpdateMonthlyChecklistItemInput;
}>;

export type UpdateMonthlyChecklistItemMutation = {
  updateMonthlyChecklistItem: boolean;
};

export type AddMonthlyChecklistItemMutationVariables = Exact<{
  input: AddMonthlyChecklistItemInput;
}>;

export type AddMonthlyChecklistItemMutation = {
  addMonthlyChecklistItem: boolean;
};

export type ClosePeriodMutationVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
}>;

export type ClosePeriodMutation = {
  closePeriod?: Maybe<{ month: number; year: number }>;
};

export type ReopenPeriodMutationVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
}>;

export type ReopenPeriodMutation = {
  reopenPeriod?: Maybe<{ month: number; year: number }>;
};

export type UpdatePeriodCloseDateMutationVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
  closeDate: Scalars['DateTime'];
}>;

export type UpdatePeriodCloseDateMutation = {
  updatePeriodCloseDate?: Maybe<{ month: number; year: number }>;
};

export type PeriodJeEmailReminderFragment = { id: number; sendDate: any };

export type PeriodJeEmailRemindersQueryVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
}>;

export type PeriodJeEmailRemindersQuery = {
  periodJeEmailReminders: Array<PeriodJeEmailReminderFragment>;
};

export type AddPeriodJeEmailReminderMutationVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
  sendDate: Scalars['DateTime'];
}>;

export type AddPeriodJeEmailReminderMutation = {
  addPeriodJeEmailReminder: PeriodJeEmailReminderFragment;
};

export type UpdatePeriodJeEmailReminderMutationVariables = Exact<{
  id: Scalars['Int'];
  sendDate: Scalars['DateTime'];
}>;

export type UpdatePeriodJeEmailReminderMutation = {
  updatePeriodJeEmailReminder: PeriodJeEmailReminderFragment;
};

export type DeletePeriodJeEmailReminderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeletePeriodJeEmailReminderMutation = {
  deletePeriodJeEmailReminder: PeriodJeEmailReminderFragment;
};

export type SendPeriodJeEmailReminderNowMutationVariables = Exact<{
  input: SendPeriodJeEmailReminderNowInput;
}>;

export type SendPeriodJeEmailReminderNowMutation = {
  sendPeriodJeEmailReminderNow: boolean;
};

export type PeriodSelectionQueryVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
}>;

export type PeriodSelectionQuery = {
  period?: Maybe<{
    closeDate?: Maybe<any>;
    initiatedDate?: Maybe<any>;
    closedDate?: Maybe<any>;
    periodStatus: { id: number; name: string };
  }>;
};

export type PeriodSelectionRangeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PeriodSelectionRangeQuery = {
  periodSelectionRange: {
    latestMonth: number;
    latestYear: number;
    earliestYear: number;
  };
};

export type JournalEntryTableDataQueryVariables = Exact<{
  filter?: Maybe<JournalEntryFilter>;
  sortOptions?: Maybe<JournalEntrySortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type JournalEntryTableDataQuery = {
  journalEntries: {
    totalItems: number;
    items: Array<JournalEntryTableDataItemFragment>;
  };
};

export type UpdateJournalEntryMutationVariables = Exact<{
  input: UpdateJournalEntryInput;
}>;

export type UpdateJournalEntryMutation = { updateJournalEntry: boolean };

export type JournalEntryCountsQueryVariables = Exact<{
  filter?: Maybe<JournalEntryFilter>;
}>;

export type JournalEntryCountsQuery = {
  journalEntryCounts: {
    totalItems: number;
    incompleteItems: number;
    completedItems: number;
  };
};

export type SkippedJournalEntryCountQueryVariables = Exact<{
  filter?: Maybe<JournalEntryFilter>;
}>;

export type SkippedJournalEntryCountQuery = {
  skippedJournalEntryCount: number;
};

export type SkippedJournalEntryTableDataQueryVariables = Exact<{
  filter?: Maybe<JournalEntryFilter>;
  sortOptions?: Maybe<JournalEntrySortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type SkippedJournalEntryTableDataQuery = {
  journalEntries: {
    totalItems: number;
    items: Array<JournalEntryTableDataItemFragment>;
  };
};

export const JeFrequencyLookupModelFragmentDoc = gql`
  fragment jeFrequencyLookupModel on JeFrequencyLookupModel {
    id
    name
    description
    periodModulus
    periodOffset
    isDeleted
  }
`;
export const LookupModelFragmentDoc = gql`
  fragment lookupModel on LookupModel {
    id
    name
    description
    isDeleted
  }
`;
export const StringLookupModelFragmentDoc = gql`
  fragment stringLookupModel on StringLookupModel {
    name
    description
    isDeleted
  }
`;
export const JournalEntryTableDataItemFragmentDoc = gql`
  fragment journalEntryTableDataItem on JournalEntry {
    id
    monthlyChecklistItem {
      id
      period {
        month
        year
        closedDate
      }
      kbsEntity {
        name
      }
      jeType {
        name
      }
      kbsGroup {
        name
      }
      responsibleUser {
        name
      }
      title
      dueDaysAfterClose
      retireYear
      retireMonth
    }
    journalId
    completedBy
    postedDate
    netsuiteTransactionNumber
    recordedDate
    comment
    isUnplanned
  }
`;
export const MasterChecklistItemForBulkUpdateFragmentDoc = gql`
  fragment masterChecklistItemForBulkUpdate on MasterChecklistItem {
    id
    title
    description
    journalId
    kbsEntity {
      id
    }
    kbsGroup {
      id
    }
    jeType {
      id
    }
    startMonth
    startYear
    retireMonth
    retireYear
    jeFrequency {
      id
      periodModulus
      periodOffset
    }
    jeFrequencyPeriodOffset
    numberOfOccurrences
    dueDaysAfterClose
    responsibleUser {
      id
    }
    sendReminder
    ignoreFromMonth
    ignoreFromYear
    ignoreUntilMonth
    ignoreUntilYear
    comment
  }
`;
export const MasterChecklistTableDataItemFragmentDoc = gql`
  fragment masterChecklistTableDataItem on MasterChecklistItem {
    id
    responsibleUser {
      name
    }
    ignoreUntilMonth
    ignoreUntilYear
    retireYear
    retireMonth
    journalId
    jeType {
      name
    }
    jeFrequency {
      name
      periodModulus
    }
    jeFrequencyPeriodOffset
    kbsEntity {
      name
    }
    kbsGroup {
      name
    }
    lastExecutedDate
    title
    dueDaysAfterClose
  }
`;
export const MasterChecklistItemDetailFragmentDoc = gql`
  fragment masterChecklistItemDetail on MasterChecklistItem {
    id
    responsibleUser {
      id
      name
    }
    comment
    description
    dueDaysAfterClose
    ignoreFromMonth
    ignoreFromYear
    ignoreUntilMonth
    ignoreUntilYear
    journalId
    jeType {
      id
    }
    jeFrequency {
      id
      periodModulus
      periodOffset
    }
    jeFrequencyPeriodOffset
    kbsEntity {
      id
    }
    kbsGroup {
      id
    }
    lastExecutedDate
    numberOfOccurrences
    sendReminder
    startMonth
    startYear
    retireMonth
    retireYear
    title
  }
`;
export const MonthlyChecklistItemViewFragmentDoc = gql`
  fragment monthlyChecklistItemView on MonthlyChecklistItemView {
    monthlyChecklistItemId
    monthlyChecklistItemAction
    fallsInPeriod
    dueDaysAfterClose
    numberOfOccurrences
    kbsEntity {
      id
      name
    }
    title
    jeType {
      id
      name
    }
    journalId
    kbsGroup {
      id
      name
    }
    jeFrequency {
      id
      name
      periodModulus
    }
    jeFrequencyPeriodOffset
    responsibleUser {
      id
      name
    }
    masterChecklistItem {
      id
      lastExecutedDate
    }
    wasAddedBySync
    period {
      closedDate
    }
    comment
  }
`;
export const PeriodJeEmailReminderFragmentDoc = gql`
  fragment periodJeEmailReminder on PeriodJeEmailReminder {
    id
    sendDate
  }
`;
export const JournalIdLookupsDocument = gql`
  query journalIdLookups($name: String, $includeDeleted: Boolean = false) {
    journalIdLookups(filter: { name: $name, includeDeleted: $includeDeleted }) {
      ...lookupModel
    }
  }
  ${LookupModelFragmentDoc}
`;

/**
 * __useJournalIdLookupsQuery__
 *
 * To run a query within a React component, call `useJournalIdLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalIdLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalIdLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useJournalIdLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JournalIdLookupsQuery,
    JournalIdLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<JournalIdLookupsQuery, JournalIdLookupsQueryVariables>(
    JournalIdLookupsDocument,
    baseOptions,
  );
}
export function useJournalIdLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JournalIdLookupsQuery,
    JournalIdLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    JournalIdLookupsQuery,
    JournalIdLookupsQueryVariables
  >(JournalIdLookupsDocument, baseOptions);
}
export type JournalIdLookupsQueryHookResult = ReturnType<
  typeof useJournalIdLookupsQuery
>;
export type JournalIdLookupsLazyQueryHookResult = ReturnType<
  typeof useJournalIdLookupsLazyQuery
>;
export type JournalIdLookupsQueryResult = Apollo.QueryResult<
  JournalIdLookupsQuery,
  JournalIdLookupsQueryVariables
>;
export const CompletedByLookupsDocument = gql`
  query completedByLookups(
    $name: String
    $periodId: Int
    $periodStartId: Int
    $periodEndId: Int
    $includeDeleted: Boolean = false
  ) {
    completedByLookups(
      filter: {
        name: $name
        periodId: $periodId
        periodStartId: $periodStartId
        periodEndId: $periodEndId
        includeDeleted: $includeDeleted
      }
    ) {
      ...stringLookupModel
    }
  }
  ${StringLookupModelFragmentDoc}
`;

/**
 * __useCompletedByLookupsQuery__
 *
 * To run a query within a React component, call `useCompletedByLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedByLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedByLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      periodId: // value for 'periodId'
 *      periodStartId: // value for 'periodStartId'
 *      periodEndId: // value for 'periodEndId'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useCompletedByLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompletedByLookupsQuery,
    CompletedByLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<
    CompletedByLookupsQuery,
    CompletedByLookupsQueryVariables
  >(CompletedByLookupsDocument, baseOptions);
}
export function useCompletedByLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompletedByLookupsQuery,
    CompletedByLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    CompletedByLookupsQuery,
    CompletedByLookupsQueryVariables
  >(CompletedByLookupsDocument, baseOptions);
}
export type CompletedByLookupsQueryHookResult = ReturnType<
  typeof useCompletedByLookupsQuery
>;
export type CompletedByLookupsLazyQueryHookResult = ReturnType<
  typeof useCompletedByLookupsLazyQuery
>;
export type CompletedByLookupsQueryResult = Apollo.QueryResult<
  CompletedByLookupsQuery,
  CompletedByLookupsQueryVariables
>;
export const JeFrequencyLookupsDocument = gql`
  query jeFrequencyLookups($name: String, $includeDeleted: Boolean = false) {
    jeFrequencies(filter: { name: $name, includeDeleted: $includeDeleted }) {
      ...jeFrequencyLookupModel
    }
  }
  ${JeFrequencyLookupModelFragmentDoc}
`;

/**
 * __useJeFrequencyLookupsQuery__
 *
 * To run a query within a React component, call `useJeFrequencyLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJeFrequencyLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJeFrequencyLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useJeFrequencyLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JeFrequencyLookupsQuery,
    JeFrequencyLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<
    JeFrequencyLookupsQuery,
    JeFrequencyLookupsQueryVariables
  >(JeFrequencyLookupsDocument, baseOptions);
}
export function useJeFrequencyLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JeFrequencyLookupsQuery,
    JeFrequencyLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    JeFrequencyLookupsQuery,
    JeFrequencyLookupsQueryVariables
  >(JeFrequencyLookupsDocument, baseOptions);
}
export type JeFrequencyLookupsQueryHookResult = ReturnType<
  typeof useJeFrequencyLookupsQuery
>;
export type JeFrequencyLookupsLazyQueryHookResult = ReturnType<
  typeof useJeFrequencyLookupsLazyQuery
>;
export type JeFrequencyLookupsQueryResult = Apollo.QueryResult<
  JeFrequencyLookupsQuery,
  JeFrequencyLookupsQueryVariables
>;
export const JeTypeLookupsDocument = gql`
  query jeTypeLookups($name: String, $includeDeleted: Boolean = false) {
    jeTypes(filter: { name: $name, includeDeleted: $includeDeleted }) {
      ...lookupModel
    }
  }
  ${LookupModelFragmentDoc}
`;

/**
 * __useJeTypeLookupsQuery__
 *
 * To run a query within a React component, call `useJeTypeLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJeTypeLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJeTypeLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useJeTypeLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JeTypeLookupsQuery,
    JeTypeLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<JeTypeLookupsQuery, JeTypeLookupsQueryVariables>(
    JeTypeLookupsDocument,
    baseOptions,
  );
}
export function useJeTypeLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JeTypeLookupsQuery,
    JeTypeLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<JeTypeLookupsQuery, JeTypeLookupsQueryVariables>(
    JeTypeLookupsDocument,
    baseOptions,
  );
}
export type JeTypeLookupsQueryHookResult = ReturnType<
  typeof useJeTypeLookupsQuery
>;
export type JeTypeLookupsLazyQueryHookResult = ReturnType<
  typeof useJeTypeLookupsLazyQuery
>;
export type JeTypeLookupsQueryResult = Apollo.QueryResult<
  JeTypeLookupsQuery,
  JeTypeLookupsQueryVariables
>;
export const KbsEntityLookupsDocument = gql`
  query kbsEntityLookups($name: String, $includeDeleted: Boolean = false) {
    kbsEntities(filter: { name: $name, includeDeleted: $includeDeleted }) {
      ...lookupModel
    }
  }
  ${LookupModelFragmentDoc}
`;

/**
 * __useKbsEntityLookupsQuery__
 *
 * To run a query within a React component, call `useKbsEntityLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKbsEntityLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKbsEntityLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useKbsEntityLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KbsEntityLookupsQuery,
    KbsEntityLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<KbsEntityLookupsQuery, KbsEntityLookupsQueryVariables>(
    KbsEntityLookupsDocument,
    baseOptions,
  );
}
export function useKbsEntityLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KbsEntityLookupsQuery,
    KbsEntityLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    KbsEntityLookupsQuery,
    KbsEntityLookupsQueryVariables
  >(KbsEntityLookupsDocument, baseOptions);
}
export type KbsEntityLookupsQueryHookResult = ReturnType<
  typeof useKbsEntityLookupsQuery
>;
export type KbsEntityLookupsLazyQueryHookResult = ReturnType<
  typeof useKbsEntityLookupsLazyQuery
>;
export type KbsEntityLookupsQueryResult = Apollo.QueryResult<
  KbsEntityLookupsQuery,
  KbsEntityLookupsQueryVariables
>;
export const KbsGroupLookupsDocument = gql`
  query kbsGroupLookups($name: String, $includeDeleted: Boolean = false) {
    kbsGroups(filter: { name: $name, includeDeleted: $includeDeleted }) {
      ...lookupModel
    }
  }
  ${LookupModelFragmentDoc}
`;

/**
 * __useKbsGroupLookupsQuery__
 *
 * To run a query within a React component, call `useKbsGroupLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKbsGroupLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKbsGroupLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useKbsGroupLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KbsGroupLookupsQuery,
    KbsGroupLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<KbsGroupLookupsQuery, KbsGroupLookupsQueryVariables>(
    KbsGroupLookupsDocument,
    baseOptions,
  );
}
export function useKbsGroupLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KbsGroupLookupsQuery,
    KbsGroupLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    KbsGroupLookupsQuery,
    KbsGroupLookupsQueryVariables
  >(KbsGroupLookupsDocument, baseOptions);
}
export type KbsGroupLookupsQueryHookResult = ReturnType<
  typeof useKbsGroupLookupsQuery
>;
export type KbsGroupLookupsLazyQueryHookResult = ReturnType<
  typeof useKbsGroupLookupsLazyQuery
>;
export type KbsGroupLookupsQueryResult = Apollo.QueryResult<
  KbsGroupLookupsQuery,
  KbsGroupLookupsQueryVariables
>;
export const PeriodLookupsDocument = gql`
  query periodLookups($name: String, $includeDeleted: Boolean = false) {
    periodLookups(filter: { name: $name, includeDeleted: $includeDeleted }) {
      ...lookupModel
    }
  }
  ${LookupModelFragmentDoc}
`;

/**
 * __usePeriodLookupsQuery__
 *
 * To run a query within a React component, call `usePeriodLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function usePeriodLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PeriodLookupsQuery,
    PeriodLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<PeriodLookupsQuery, PeriodLookupsQueryVariables>(
    PeriodLookupsDocument,
    baseOptions,
  );
}
export function usePeriodLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodLookupsQuery,
    PeriodLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<PeriodLookupsQuery, PeriodLookupsQueryVariables>(
    PeriodLookupsDocument,
    baseOptions,
  );
}
export type PeriodLookupsQueryHookResult = ReturnType<
  typeof usePeriodLookupsQuery
>;
export type PeriodLookupsLazyQueryHookResult = ReturnType<
  typeof usePeriodLookupsLazyQuery
>;
export type PeriodLookupsQueryResult = Apollo.QueryResult<
  PeriodLookupsQuery,
  PeriodLookupsQueryVariables
>;
export const UserLookupsDocument = gql`
  query userLookups(
    $name: String
    $includeDeleted: Boolean = false
    $includeInactive: Boolean = false
    $includeId: Int
  ) {
    userLookups(
      filter: {
        name: $name
        includeDeleted: $includeDeleted
        includeInactive: $includeInactive
        includeId: $includeId
      }
    ) {
      ...lookupModel
    }
  }
  ${LookupModelFragmentDoc}
`;

/**
 * __useUserLookupsQuery__
 *
 * To run a query within a React component, call `useUserLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeDeleted: // value for 'includeDeleted'
 *      includeInactive: // value for 'includeInactive'
 *      includeId: // value for 'includeId'
 *   },
 * });
 */
export function useUserLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserLookupsQuery,
    UserLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<UserLookupsQuery, UserLookupsQueryVariables>(
    UserLookupsDocument,
    baseOptions,
  );
}
export function useUserLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserLookupsQuery,
    UserLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<UserLookupsQuery, UserLookupsQueryVariables>(
    UserLookupsDocument,
    baseOptions,
  );
}
export type UserLookupsQueryHookResult = ReturnType<typeof useUserLookupsQuery>;
export type UserLookupsLazyQueryHookResult = ReturnType<
  typeof useUserLookupsLazyQuery
>;
export type UserLookupsQueryResult = Apollo.QueryResult<
  UserLookupsQuery,
  UserLookupsQueryVariables
>;
export const LookupMasterChecklistItemsForBulkUpdateDocument = gql`
  query lookupMasterChecklistItemsForBulkUpdate($ids: [String!]) {
    masterChecklistItems(filter: { journalIdIn: $ids }) {
      items {
        ...masterChecklistItemForBulkUpdate
      }
    }
  }
  ${MasterChecklistItemForBulkUpdateFragmentDoc}
`;

/**
 * __useLookupMasterChecklistItemsForBulkUpdateQuery__
 *
 * To run a query within a React component, call `useLookupMasterChecklistItemsForBulkUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupMasterChecklistItemsForBulkUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupMasterChecklistItemsForBulkUpdateQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLookupMasterChecklistItemsForBulkUpdateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LookupMasterChecklistItemsForBulkUpdateQuery,
    LookupMasterChecklistItemsForBulkUpdateQueryVariables
  >,
) {
  return Apollo.useQuery<
    LookupMasterChecklistItemsForBulkUpdateQuery,
    LookupMasterChecklistItemsForBulkUpdateQueryVariables
  >(LookupMasterChecklistItemsForBulkUpdateDocument, baseOptions);
}
export function useLookupMasterChecklistItemsForBulkUpdateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LookupMasterChecklistItemsForBulkUpdateQuery,
    LookupMasterChecklistItemsForBulkUpdateQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    LookupMasterChecklistItemsForBulkUpdateQuery,
    LookupMasterChecklistItemsForBulkUpdateQueryVariables
  >(LookupMasterChecklistItemsForBulkUpdateDocument, baseOptions);
}
export type LookupMasterChecklistItemsForBulkUpdateQueryHookResult = ReturnType<
  typeof useLookupMasterChecklistItemsForBulkUpdateQuery
>;
export type LookupMasterChecklistItemsForBulkUpdateLazyQueryHookResult =
  ReturnType<typeof useLookupMasterChecklistItemsForBulkUpdateLazyQuery>;
export type LookupMasterChecklistItemsForBulkUpdateQueryResult =
  Apollo.QueryResult<
    LookupMasterChecklistItemsForBulkUpdateQuery,
    LookupMasterChecklistItemsForBulkUpdateQueryVariables
  >;
export const BulkUpdateMasterChecklistItemsDocument = gql`
  mutation bulkUpdateMasterChecklistItems(
    $input: BulkUpdateMasterChecklistItemsInput!
  ) {
    bulkUpdateMasterChecklistItems(input: $input) {
      id
    }
  }
`;
export type BulkUpdateMasterChecklistItemsMutationFn = Apollo.MutationFunction<
  BulkUpdateMasterChecklistItemsMutation,
  BulkUpdateMasterChecklistItemsMutationVariables
>;

/**
 * __useBulkUpdateMasterChecklistItemsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateMasterChecklistItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateMasterChecklistItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateMasterChecklistItemsMutation, { data, loading, error }] = useBulkUpdateMasterChecklistItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateMasterChecklistItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateMasterChecklistItemsMutation,
    BulkUpdateMasterChecklistItemsMutationVariables
  >,
) {
  return Apollo.useMutation<
    BulkUpdateMasterChecklistItemsMutation,
    BulkUpdateMasterChecklistItemsMutationVariables
  >(BulkUpdateMasterChecklistItemsDocument, baseOptions);
}
export type BulkUpdateMasterChecklistItemsMutationHookResult = ReturnType<
  typeof useBulkUpdateMasterChecklistItemsMutation
>;
export type BulkUpdateMasterChecklistItemsMutationResult =
  Apollo.MutationResult<BulkUpdateMasterChecklistItemsMutation>;
export type BulkUpdateMasterChecklistItemsMutationOptions =
  Apollo.BaseMutationOptions<
    BulkUpdateMasterChecklistItemsMutation,
    BulkUpdateMasterChecklistItemsMutationVariables
  >;
export const MasterChecklistTableDataDocument = gql`
  query masterChecklistTableData(
    $filter: MasterChecklistItemFilter
    $sortOptions: MasterChecklistItemSortOptions
    $paginationOptions: PaginationOptions
  ) {
    masterChecklistItems(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...masterChecklistTableDataItem
      }
    }
  }
  ${MasterChecklistTableDataItemFragmentDoc}
`;

/**
 * __useMasterChecklistTableDataQuery__
 *
 * To run a query within a React component, call `useMasterChecklistTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterChecklistTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterChecklistTableDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useMasterChecklistTableDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MasterChecklistTableDataQuery,
    MasterChecklistTableDataQueryVariables
  >,
) {
  return Apollo.useQuery<
    MasterChecklistTableDataQuery,
    MasterChecklistTableDataQueryVariables
  >(MasterChecklistTableDataDocument, baseOptions);
}
export function useMasterChecklistTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MasterChecklistTableDataQuery,
    MasterChecklistTableDataQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    MasterChecklistTableDataQuery,
    MasterChecklistTableDataQueryVariables
  >(MasterChecklistTableDataDocument, baseOptions);
}
export type MasterChecklistTableDataQueryHookResult = ReturnType<
  typeof useMasterChecklistTableDataQuery
>;
export type MasterChecklistTableDataLazyQueryHookResult = ReturnType<
  typeof useMasterChecklistTableDataLazyQuery
>;
export type MasterChecklistTableDataQueryResult = Apollo.QueryResult<
  MasterChecklistTableDataQuery,
  MasterChecklistTableDataQueryVariables
>;
export const MasterChecklistItemDetailDocument = gql`
  query masterChecklistItemDetail($id: Int!) {
    masterChecklistItem(id: $id) {
      ...masterChecklistItemDetail
    }
  }
  ${MasterChecklistItemDetailFragmentDoc}
`;

/**
 * __useMasterChecklistItemDetailQuery__
 *
 * To run a query within a React component, call `useMasterChecklistItemDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterChecklistItemDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterChecklistItemDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMasterChecklistItemDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    MasterChecklistItemDetailQuery,
    MasterChecklistItemDetailQueryVariables
  >,
) {
  return Apollo.useQuery<
    MasterChecklistItemDetailQuery,
    MasterChecklistItemDetailQueryVariables
  >(MasterChecklistItemDetailDocument, baseOptions);
}
export function useMasterChecklistItemDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MasterChecklistItemDetailQuery,
    MasterChecklistItemDetailQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    MasterChecklistItemDetailQuery,
    MasterChecklistItemDetailQueryVariables
  >(MasterChecklistItemDetailDocument, baseOptions);
}
export type MasterChecklistItemDetailQueryHookResult = ReturnType<
  typeof useMasterChecklistItemDetailQuery
>;
export type MasterChecklistItemDetailLazyQueryHookResult = ReturnType<
  typeof useMasterChecklistItemDetailLazyQuery
>;
export type MasterChecklistItemDetailQueryResult = Apollo.QueryResult<
  MasterChecklistItemDetailQuery,
  MasterChecklistItemDetailQueryVariables
>;
export const CalculateNextScheduledDateDocument = gql`
  mutation calculateNextScheduledDate(
    $lastExecutedDate: DateTime!
    $frequency: Int!
    $frequencyPeriodOffset: Int!
  ) {
    calculateNextScheduledDate(
      lastExecutedDate: $lastExecutedDate
      frequency: $frequency
      frequencyPeriodOffset: $frequencyPeriodOffset
    )
  }
`;
export type CalculateNextScheduledDateMutationFn = Apollo.MutationFunction<
  CalculateNextScheduledDateMutation,
  CalculateNextScheduledDateMutationVariables
>;

/**
 * __useCalculateNextScheduledDateMutation__
 *
 * To run a mutation, you first call `useCalculateNextScheduledDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateNextScheduledDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateNextScheduledDateMutation, { data, loading, error }] = useCalculateNextScheduledDateMutation({
 *   variables: {
 *      lastExecutedDate: // value for 'lastExecutedDate'
 *      frequency: // value for 'frequency'
 *      frequencyPeriodOffset: // value for 'frequencyPeriodOffset'
 *   },
 * });
 */
export function useCalculateNextScheduledDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CalculateNextScheduledDateMutation,
    CalculateNextScheduledDateMutationVariables
  >,
) {
  return Apollo.useMutation<
    CalculateNextScheduledDateMutation,
    CalculateNextScheduledDateMutationVariables
  >(CalculateNextScheduledDateDocument, baseOptions);
}
export type CalculateNextScheduledDateMutationHookResult = ReturnType<
  typeof useCalculateNextScheduledDateMutation
>;
export type CalculateNextScheduledDateMutationResult =
  Apollo.MutationResult<CalculateNextScheduledDateMutation>;
export type CalculateNextScheduledDateMutationOptions =
  Apollo.BaseMutationOptions<
    CalculateNextScheduledDateMutation,
    CalculateNextScheduledDateMutationVariables
  >;
export const CreateMasterChecklistItemDocument = gql`
  mutation createMasterChecklistItem($input: MasterChecklistItemInput!) {
    createMasterChecklistItem(input: $input) {
      id
    }
  }
`;
export type CreateMasterChecklistItemMutationFn = Apollo.MutationFunction<
  CreateMasterChecklistItemMutation,
  CreateMasterChecklistItemMutationVariables
>;

/**
 * __useCreateMasterChecklistItemMutation__
 *
 * To run a mutation, you first call `useCreateMasterChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMasterChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMasterChecklistItemMutation, { data, loading, error }] = useCreateMasterChecklistItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMasterChecklistItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMasterChecklistItemMutation,
    CreateMasterChecklistItemMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateMasterChecklistItemMutation,
    CreateMasterChecklistItemMutationVariables
  >(CreateMasterChecklistItemDocument, baseOptions);
}
export type CreateMasterChecklistItemMutationHookResult = ReturnType<
  typeof useCreateMasterChecklistItemMutation
>;
export type CreateMasterChecklistItemMutationResult =
  Apollo.MutationResult<CreateMasterChecklistItemMutation>;
export type CreateMasterChecklistItemMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMasterChecklistItemMutation,
    CreateMasterChecklistItemMutationVariables
  >;
export const UpdateMasterChecklistItemDocument = gql`
  mutation updateMasterChecklistItem(
    $id: Int!
    $input: MasterChecklistItemInput!
  ) {
    updateMasterChecklistItem(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateMasterChecklistItemMutationFn = Apollo.MutationFunction<
  UpdateMasterChecklistItemMutation,
  UpdateMasterChecklistItemMutationVariables
>;

/**
 * __useUpdateMasterChecklistItemMutation__
 *
 * To run a mutation, you first call `useUpdateMasterChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterChecklistItemMutation, { data, loading, error }] = useUpdateMasterChecklistItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMasterChecklistItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMasterChecklistItemMutation,
    UpdateMasterChecklistItemMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateMasterChecklistItemMutation,
    UpdateMasterChecklistItemMutationVariables
  >(UpdateMasterChecklistItemDocument, baseOptions);
}
export type UpdateMasterChecklistItemMutationHookResult = ReturnType<
  typeof useUpdateMasterChecklistItemMutation
>;
export type UpdateMasterChecklistItemMutationResult =
  Apollo.MutationResult<UpdateMasterChecklistItemMutation>;
export type UpdateMasterChecklistItemMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMasterChecklistItemMutation,
    UpdateMasterChecklistItemMutationVariables
  >;
export const InitiatePeriodDocument = gql`
  mutation initiatePeriod($month: Int!, $year: Int!, $closeDate: DateTime!) {
    initiatePeriod(input: { month: $month, year: $year, closeDate: $closeDate })
  }
`;
export type InitiatePeriodMutationFn = Apollo.MutationFunction<
  InitiatePeriodMutation,
  InitiatePeriodMutationVariables
>;

/**
 * __useInitiatePeriodMutation__
 *
 * To run a mutation, you first call `useInitiatePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiatePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiatePeriodMutation, { data, loading, error }] = useInitiatePeriodMutation({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      closeDate: // value for 'closeDate'
 *   },
 * });
 */
export function useInitiatePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiatePeriodMutation,
    InitiatePeriodMutationVariables
  >,
) {
  return Apollo.useMutation<
    InitiatePeriodMutation,
    InitiatePeriodMutationVariables
  >(InitiatePeriodDocument, baseOptions);
}
export type InitiatePeriodMutationHookResult = ReturnType<
  typeof useInitiatePeriodMutation
>;
export type InitiatePeriodMutationResult =
  Apollo.MutationResult<InitiatePeriodMutation>;
export type InitiatePeriodMutationOptions = Apollo.BaseMutationOptions<
  InitiatePeriodMutation,
  InitiatePeriodMutationVariables
>;
export const FetchMonthlyChecklistItemsDocument = gql`
  query fetchMonthlyChecklistItems(
    $month: Int!
    $year: Int!
    $allChecklistItems: Boolean
    $sortBy: MonthlyChecklistItemViewSortBy
    $sortDirection: SortDirection
    $take: Int
    $skip: Int
  ) {
    monthlyChecklistItemViews(
      filter: {
        month: $month
        year: $year
        includeUnscheduled: $allChecklistItems
      }
      sortOptions: { sortBy: $sortBy, sortDirection: $sortDirection }
      paginationOptions: { skip: $skip, take: $take }
    ) {
      totalItems
      items {
        ...monthlyChecklistItemView
      }
    }
  }
  ${MonthlyChecklistItemViewFragmentDoc}
`;

/**
 * __useFetchMonthlyChecklistItemsQuery__
 *
 * To run a query within a React component, call `useFetchMonthlyChecklistItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMonthlyChecklistItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMonthlyChecklistItemsQuery({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      allChecklistItems: // value for 'allChecklistItems'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useFetchMonthlyChecklistItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMonthlyChecklistItemsQuery,
    FetchMonthlyChecklistItemsQueryVariables
  >,
) {
  return Apollo.useQuery<
    FetchMonthlyChecklistItemsQuery,
    FetchMonthlyChecklistItemsQueryVariables
  >(FetchMonthlyChecklistItemsDocument, baseOptions);
}
export function useFetchMonthlyChecklistItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMonthlyChecklistItemsQuery,
    FetchMonthlyChecklistItemsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    FetchMonthlyChecklistItemsQuery,
    FetchMonthlyChecklistItemsQueryVariables
  >(FetchMonthlyChecklistItemsDocument, baseOptions);
}
export type FetchMonthlyChecklistItemsQueryHookResult = ReturnType<
  typeof useFetchMonthlyChecklistItemsQuery
>;
export type FetchMonthlyChecklistItemsLazyQueryHookResult = ReturnType<
  typeof useFetchMonthlyChecklistItemsLazyQuery
>;
export type FetchMonthlyChecklistItemsQueryResult = Apollo.QueryResult<
  FetchMonthlyChecklistItemsQuery,
  FetchMonthlyChecklistItemsQueryVariables
>;
export const UpdateMonthlyChecklistItemDocument = gql`
  mutation updateMonthlyChecklistItem(
    $input: UpdateMonthlyChecklistItemInput!
  ) {
    updateMonthlyChecklistItem(input: $input)
  }
`;
export type UpdateMonthlyChecklistItemMutationFn = Apollo.MutationFunction<
  UpdateMonthlyChecklistItemMutation,
  UpdateMonthlyChecklistItemMutationVariables
>;

/**
 * __useUpdateMonthlyChecklistItemMutation__
 *
 * To run a mutation, you first call `useUpdateMonthlyChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonthlyChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonthlyChecklistItemMutation, { data, loading, error }] = useUpdateMonthlyChecklistItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMonthlyChecklistItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMonthlyChecklistItemMutation,
    UpdateMonthlyChecklistItemMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateMonthlyChecklistItemMutation,
    UpdateMonthlyChecklistItemMutationVariables
  >(UpdateMonthlyChecklistItemDocument, baseOptions);
}
export type UpdateMonthlyChecklistItemMutationHookResult = ReturnType<
  typeof useUpdateMonthlyChecklistItemMutation
>;
export type UpdateMonthlyChecklistItemMutationResult =
  Apollo.MutationResult<UpdateMonthlyChecklistItemMutation>;
export type UpdateMonthlyChecklistItemMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMonthlyChecklistItemMutation,
    UpdateMonthlyChecklistItemMutationVariables
  >;
export const AddMonthlyChecklistItemDocument = gql`
  mutation addMonthlyChecklistItem($input: AddMonthlyChecklistItemInput!) {
    addMonthlyChecklistItem(input: $input)
  }
`;
export type AddMonthlyChecklistItemMutationFn = Apollo.MutationFunction<
  AddMonthlyChecklistItemMutation,
  AddMonthlyChecklistItemMutationVariables
>;

/**
 * __useAddMonthlyChecklistItemMutation__
 *
 * To run a mutation, you first call `useAddMonthlyChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMonthlyChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMonthlyChecklistItemMutation, { data, loading, error }] = useAddMonthlyChecklistItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMonthlyChecklistItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMonthlyChecklistItemMutation,
    AddMonthlyChecklistItemMutationVariables
  >,
) {
  return Apollo.useMutation<
    AddMonthlyChecklistItemMutation,
    AddMonthlyChecklistItemMutationVariables
  >(AddMonthlyChecklistItemDocument, baseOptions);
}
export type AddMonthlyChecklistItemMutationHookResult = ReturnType<
  typeof useAddMonthlyChecklistItemMutation
>;
export type AddMonthlyChecklistItemMutationResult =
  Apollo.MutationResult<AddMonthlyChecklistItemMutation>;
export type AddMonthlyChecklistItemMutationOptions = Apollo.BaseMutationOptions<
  AddMonthlyChecklistItemMutation,
  AddMonthlyChecklistItemMutationVariables
>;
export const ClosePeriodDocument = gql`
  mutation closePeriod($month: Int!, $year: Int!) {
    closePeriod(month: $month, year: $year) {
      month
      year
    }
  }
`;
export type ClosePeriodMutationFn = Apollo.MutationFunction<
  ClosePeriodMutation,
  ClosePeriodMutationVariables
>;

/**
 * __useClosePeriodMutation__
 *
 * To run a mutation, you first call `useClosePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClosePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closePeriodMutation, { data, loading, error }] = useClosePeriodMutation({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useClosePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClosePeriodMutation,
    ClosePeriodMutationVariables
  >,
) {
  return Apollo.useMutation<ClosePeriodMutation, ClosePeriodMutationVariables>(
    ClosePeriodDocument,
    baseOptions,
  );
}
export type ClosePeriodMutationHookResult = ReturnType<
  typeof useClosePeriodMutation
>;
export type ClosePeriodMutationResult =
  Apollo.MutationResult<ClosePeriodMutation>;
export type ClosePeriodMutationOptions = Apollo.BaseMutationOptions<
  ClosePeriodMutation,
  ClosePeriodMutationVariables
>;
export const ReopenPeriodDocument = gql`
  mutation reopenPeriod($month: Int!, $year: Int!) {
    reopenPeriod(month: $month, year: $year) {
      month
      year
    }
  }
`;
export type ReopenPeriodMutationFn = Apollo.MutationFunction<
  ReopenPeriodMutation,
  ReopenPeriodMutationVariables
>;

/**
 * __useReopenPeriodMutation__
 *
 * To run a mutation, you first call `useReopenPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenPeriodMutation, { data, loading, error }] = useReopenPeriodMutation({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useReopenPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReopenPeriodMutation,
    ReopenPeriodMutationVariables
  >,
) {
  return Apollo.useMutation<
    ReopenPeriodMutation,
    ReopenPeriodMutationVariables
  >(ReopenPeriodDocument, baseOptions);
}
export type ReopenPeriodMutationHookResult = ReturnType<
  typeof useReopenPeriodMutation
>;
export type ReopenPeriodMutationResult =
  Apollo.MutationResult<ReopenPeriodMutation>;
export type ReopenPeriodMutationOptions = Apollo.BaseMutationOptions<
  ReopenPeriodMutation,
  ReopenPeriodMutationVariables
>;
export const UpdatePeriodCloseDateDocument = gql`
  mutation updatePeriodCloseDate(
    $month: Int!
    $year: Int!
    $closeDate: DateTime!
  ) {
    updatePeriodCloseDate(month: $month, year: $year, closeDate: $closeDate) {
      month
      year
    }
  }
`;
export type UpdatePeriodCloseDateMutationFn = Apollo.MutationFunction<
  UpdatePeriodCloseDateMutation,
  UpdatePeriodCloseDateMutationVariables
>;

/**
 * __useUpdatePeriodCloseDateMutation__
 *
 * To run a mutation, you first call `useUpdatePeriodCloseDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeriodCloseDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeriodCloseDateMutation, { data, loading, error }] = useUpdatePeriodCloseDateMutation({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      closeDate: // value for 'closeDate'
 *   },
 * });
 */
export function useUpdatePeriodCloseDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePeriodCloseDateMutation,
    UpdatePeriodCloseDateMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdatePeriodCloseDateMutation,
    UpdatePeriodCloseDateMutationVariables
  >(UpdatePeriodCloseDateDocument, baseOptions);
}
export type UpdatePeriodCloseDateMutationHookResult = ReturnType<
  typeof useUpdatePeriodCloseDateMutation
>;
export type UpdatePeriodCloseDateMutationResult =
  Apollo.MutationResult<UpdatePeriodCloseDateMutation>;
export type UpdatePeriodCloseDateMutationOptions = Apollo.BaseMutationOptions<
  UpdatePeriodCloseDateMutation,
  UpdatePeriodCloseDateMutationVariables
>;
export const PeriodJeEmailRemindersDocument = gql`
  query periodJeEmailReminders($month: Int!, $year: Int!) {
    periodJeEmailReminders(month: $month, year: $year) {
      ...periodJeEmailReminder
    }
  }
  ${PeriodJeEmailReminderFragmentDoc}
`;

/**
 * __usePeriodJeEmailRemindersQuery__
 *
 * To run a query within a React component, call `usePeriodJeEmailRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodJeEmailRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodJeEmailRemindersQuery({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function usePeriodJeEmailRemindersQuery(
  baseOptions: Apollo.QueryHookOptions<
    PeriodJeEmailRemindersQuery,
    PeriodJeEmailRemindersQueryVariables
  >,
) {
  return Apollo.useQuery<
    PeriodJeEmailRemindersQuery,
    PeriodJeEmailRemindersQueryVariables
  >(PeriodJeEmailRemindersDocument, baseOptions);
}
export function usePeriodJeEmailRemindersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodJeEmailRemindersQuery,
    PeriodJeEmailRemindersQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    PeriodJeEmailRemindersQuery,
    PeriodJeEmailRemindersQueryVariables
  >(PeriodJeEmailRemindersDocument, baseOptions);
}
export type PeriodJeEmailRemindersQueryHookResult = ReturnType<
  typeof usePeriodJeEmailRemindersQuery
>;
export type PeriodJeEmailRemindersLazyQueryHookResult = ReturnType<
  typeof usePeriodJeEmailRemindersLazyQuery
>;
export type PeriodJeEmailRemindersQueryResult = Apollo.QueryResult<
  PeriodJeEmailRemindersQuery,
  PeriodJeEmailRemindersQueryVariables
>;
export const AddPeriodJeEmailReminderDocument = gql`
  mutation addPeriodJeEmailReminder(
    $month: Int!
    $year: Int!
    $sendDate: DateTime!
  ) {
    addPeriodJeEmailReminder(
      input: { month: $month, year: $year, sendDate: $sendDate }
    ) {
      ...periodJeEmailReminder
    }
  }
  ${PeriodJeEmailReminderFragmentDoc}
`;
export type AddPeriodJeEmailReminderMutationFn = Apollo.MutationFunction<
  AddPeriodJeEmailReminderMutation,
  AddPeriodJeEmailReminderMutationVariables
>;

/**
 * __useAddPeriodJeEmailReminderMutation__
 *
 * To run a mutation, you first call `useAddPeriodJeEmailReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPeriodJeEmailReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPeriodJeEmailReminderMutation, { data, loading, error }] = useAddPeriodJeEmailReminderMutation({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      sendDate: // value for 'sendDate'
 *   },
 * });
 */
export function useAddPeriodJeEmailReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPeriodJeEmailReminderMutation,
    AddPeriodJeEmailReminderMutationVariables
  >,
) {
  return Apollo.useMutation<
    AddPeriodJeEmailReminderMutation,
    AddPeriodJeEmailReminderMutationVariables
  >(AddPeriodJeEmailReminderDocument, baseOptions);
}
export type AddPeriodJeEmailReminderMutationHookResult = ReturnType<
  typeof useAddPeriodJeEmailReminderMutation
>;
export type AddPeriodJeEmailReminderMutationResult =
  Apollo.MutationResult<AddPeriodJeEmailReminderMutation>;
export type AddPeriodJeEmailReminderMutationOptions =
  Apollo.BaseMutationOptions<
    AddPeriodJeEmailReminderMutation,
    AddPeriodJeEmailReminderMutationVariables
  >;
export const UpdatePeriodJeEmailReminderDocument = gql`
  mutation updatePeriodJeEmailReminder($id: Int!, $sendDate: DateTime!) {
    updatePeriodJeEmailReminder(input: { id: $id, sendDate: $sendDate }) {
      ...periodJeEmailReminder
    }
  }
  ${PeriodJeEmailReminderFragmentDoc}
`;
export type UpdatePeriodJeEmailReminderMutationFn = Apollo.MutationFunction<
  UpdatePeriodJeEmailReminderMutation,
  UpdatePeriodJeEmailReminderMutationVariables
>;

/**
 * __useUpdatePeriodJeEmailReminderMutation__
 *
 * To run a mutation, you first call `useUpdatePeriodJeEmailReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeriodJeEmailReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeriodJeEmailReminderMutation, { data, loading, error }] = useUpdatePeriodJeEmailReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sendDate: // value for 'sendDate'
 *   },
 * });
 */
export function useUpdatePeriodJeEmailReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePeriodJeEmailReminderMutation,
    UpdatePeriodJeEmailReminderMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdatePeriodJeEmailReminderMutation,
    UpdatePeriodJeEmailReminderMutationVariables
  >(UpdatePeriodJeEmailReminderDocument, baseOptions);
}
export type UpdatePeriodJeEmailReminderMutationHookResult = ReturnType<
  typeof useUpdatePeriodJeEmailReminderMutation
>;
export type UpdatePeriodJeEmailReminderMutationResult =
  Apollo.MutationResult<UpdatePeriodJeEmailReminderMutation>;
export type UpdatePeriodJeEmailReminderMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePeriodJeEmailReminderMutation,
    UpdatePeriodJeEmailReminderMutationVariables
  >;
export const DeletePeriodJeEmailReminderDocument = gql`
  mutation deletePeriodJeEmailReminder($id: Int!) {
    deletePeriodJeEmailReminder(id: $id) {
      ...periodJeEmailReminder
    }
  }
  ${PeriodJeEmailReminderFragmentDoc}
`;
export type DeletePeriodJeEmailReminderMutationFn = Apollo.MutationFunction<
  DeletePeriodJeEmailReminderMutation,
  DeletePeriodJeEmailReminderMutationVariables
>;

/**
 * __useDeletePeriodJeEmailReminderMutation__
 *
 * To run a mutation, you first call `useDeletePeriodJeEmailReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePeriodJeEmailReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePeriodJeEmailReminderMutation, { data, loading, error }] = useDeletePeriodJeEmailReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePeriodJeEmailReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePeriodJeEmailReminderMutation,
    DeletePeriodJeEmailReminderMutationVariables
  >,
) {
  return Apollo.useMutation<
    DeletePeriodJeEmailReminderMutation,
    DeletePeriodJeEmailReminderMutationVariables
  >(DeletePeriodJeEmailReminderDocument, baseOptions);
}
export type DeletePeriodJeEmailReminderMutationHookResult = ReturnType<
  typeof useDeletePeriodJeEmailReminderMutation
>;
export type DeletePeriodJeEmailReminderMutationResult =
  Apollo.MutationResult<DeletePeriodJeEmailReminderMutation>;
export type DeletePeriodJeEmailReminderMutationOptions =
  Apollo.BaseMutationOptions<
    DeletePeriodJeEmailReminderMutation,
    DeletePeriodJeEmailReminderMutationVariables
  >;
export const SendPeriodJeEmailReminderNowDocument = gql`
  mutation sendPeriodJeEmailReminderNow(
    $input: SendPeriodJeEmailReminderNowInput!
  ) {
    sendPeriodJeEmailReminderNow(input: $input)
  }
`;
export type SendPeriodJeEmailReminderNowMutationFn = Apollo.MutationFunction<
  SendPeriodJeEmailReminderNowMutation,
  SendPeriodJeEmailReminderNowMutationVariables
>;

/**
 * __useSendPeriodJeEmailReminderNowMutation__
 *
 * To run a mutation, you first call `useSendPeriodJeEmailReminderNowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPeriodJeEmailReminderNowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPeriodJeEmailReminderNowMutation, { data, loading, error }] = useSendPeriodJeEmailReminderNowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPeriodJeEmailReminderNowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPeriodJeEmailReminderNowMutation,
    SendPeriodJeEmailReminderNowMutationVariables
  >,
) {
  return Apollo.useMutation<
    SendPeriodJeEmailReminderNowMutation,
    SendPeriodJeEmailReminderNowMutationVariables
  >(SendPeriodJeEmailReminderNowDocument, baseOptions);
}
export type SendPeriodJeEmailReminderNowMutationHookResult = ReturnType<
  typeof useSendPeriodJeEmailReminderNowMutation
>;
export type SendPeriodJeEmailReminderNowMutationResult =
  Apollo.MutationResult<SendPeriodJeEmailReminderNowMutation>;
export type SendPeriodJeEmailReminderNowMutationOptions =
  Apollo.BaseMutationOptions<
    SendPeriodJeEmailReminderNowMutation,
    SendPeriodJeEmailReminderNowMutationVariables
  >;
export const PeriodSelectionDocument = gql`
  query periodSelection($month: Int!, $year: Int!) {
    period(filter: { month: $month, year: $year }) {
      periodStatus {
        id
        name
      }
      closeDate
      initiatedDate
      closedDate
    }
  }
`;

/**
 * __usePeriodSelectionQuery__
 *
 * To run a query within a React component, call `usePeriodSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodSelectionQuery({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function usePeriodSelectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    PeriodSelectionQuery,
    PeriodSelectionQueryVariables
  >,
) {
  return Apollo.useQuery<PeriodSelectionQuery, PeriodSelectionQueryVariables>(
    PeriodSelectionDocument,
    baseOptions,
  );
}
export function usePeriodSelectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodSelectionQuery,
    PeriodSelectionQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    PeriodSelectionQuery,
    PeriodSelectionQueryVariables
  >(PeriodSelectionDocument, baseOptions);
}
export type PeriodSelectionQueryHookResult = ReturnType<
  typeof usePeriodSelectionQuery
>;
export type PeriodSelectionLazyQueryHookResult = ReturnType<
  typeof usePeriodSelectionLazyQuery
>;
export type PeriodSelectionQueryResult = Apollo.QueryResult<
  PeriodSelectionQuery,
  PeriodSelectionQueryVariables
>;
export const PeriodSelectionRangeDocument = gql`
  query periodSelectionRange {
    periodSelectionRange {
      latestMonth
      latestYear
      earliestYear
    }
  }
`;

/**
 * __usePeriodSelectionRangeQuery__
 *
 * To run a query within a React component, call `usePeriodSelectionRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodSelectionRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodSelectionRangeQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeriodSelectionRangeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PeriodSelectionRangeQuery,
    PeriodSelectionRangeQueryVariables
  >,
) {
  return Apollo.useQuery<
    PeriodSelectionRangeQuery,
    PeriodSelectionRangeQueryVariables
  >(PeriodSelectionRangeDocument, baseOptions);
}
export function usePeriodSelectionRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodSelectionRangeQuery,
    PeriodSelectionRangeQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    PeriodSelectionRangeQuery,
    PeriodSelectionRangeQueryVariables
  >(PeriodSelectionRangeDocument, baseOptions);
}
export type PeriodSelectionRangeQueryHookResult = ReturnType<
  typeof usePeriodSelectionRangeQuery
>;
export type PeriodSelectionRangeLazyQueryHookResult = ReturnType<
  typeof usePeriodSelectionRangeLazyQuery
>;
export type PeriodSelectionRangeQueryResult = Apollo.QueryResult<
  PeriodSelectionRangeQuery,
  PeriodSelectionRangeQueryVariables
>;
export const JournalEntryTableDataDocument = gql`
  query journalEntryTableData(
    $filter: JournalEntryFilter
    $sortOptions: JournalEntrySortOptions
    $paginationOptions: PaginationOptions
  ) {
    journalEntries(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...journalEntryTableDataItem
      }
    }
  }
  ${JournalEntryTableDataItemFragmentDoc}
`;

/**
 * __useJournalEntryTableDataQuery__
 *
 * To run a query within a React component, call `useJournalEntryTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalEntryTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalEntryTableDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useJournalEntryTableDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JournalEntryTableDataQuery,
    JournalEntryTableDataQueryVariables
  >,
) {
  return Apollo.useQuery<
    JournalEntryTableDataQuery,
    JournalEntryTableDataQueryVariables
  >(JournalEntryTableDataDocument, baseOptions);
}
export function useJournalEntryTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JournalEntryTableDataQuery,
    JournalEntryTableDataQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    JournalEntryTableDataQuery,
    JournalEntryTableDataQueryVariables
  >(JournalEntryTableDataDocument, baseOptions);
}
export type JournalEntryTableDataQueryHookResult = ReturnType<
  typeof useJournalEntryTableDataQuery
>;
export type JournalEntryTableDataLazyQueryHookResult = ReturnType<
  typeof useJournalEntryTableDataLazyQuery
>;
export type JournalEntryTableDataQueryResult = Apollo.QueryResult<
  JournalEntryTableDataQuery,
  JournalEntryTableDataQueryVariables
>;
export const UpdateJournalEntryDocument = gql`
  mutation updateJournalEntry($input: UpdateJournalEntryInput!) {
    updateJournalEntry(input: $input)
  }
`;
export type UpdateJournalEntryMutationFn = Apollo.MutationFunction<
  UpdateJournalEntryMutation,
  UpdateJournalEntryMutationVariables
>;

/**
 * __useUpdateJournalEntryMutation__
 *
 * To run a mutation, you first call `useUpdateJournalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJournalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJournalEntryMutation, { data, loading, error }] = useUpdateJournalEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJournalEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateJournalEntryMutation,
    UpdateJournalEntryMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateJournalEntryMutation,
    UpdateJournalEntryMutationVariables
  >(UpdateJournalEntryDocument, baseOptions);
}
export type UpdateJournalEntryMutationHookResult = ReturnType<
  typeof useUpdateJournalEntryMutation
>;
export type UpdateJournalEntryMutationResult =
  Apollo.MutationResult<UpdateJournalEntryMutation>;
export type UpdateJournalEntryMutationOptions = Apollo.BaseMutationOptions<
  UpdateJournalEntryMutation,
  UpdateJournalEntryMutationVariables
>;
export const JournalEntryCountsDocument = gql`
  query journalEntryCounts($filter: JournalEntryFilter) {
    journalEntryCounts(filter: $filter) {
      totalItems
      incompleteItems
      completedItems
    }
  }
`;

/**
 * __useJournalEntryCountsQuery__
 *
 * To run a query within a React component, call `useJournalEntryCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalEntryCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalEntryCountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useJournalEntryCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JournalEntryCountsQuery,
    JournalEntryCountsQueryVariables
  >,
) {
  return Apollo.useQuery<
    JournalEntryCountsQuery,
    JournalEntryCountsQueryVariables
  >(JournalEntryCountsDocument, baseOptions);
}
export function useJournalEntryCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JournalEntryCountsQuery,
    JournalEntryCountsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    JournalEntryCountsQuery,
    JournalEntryCountsQueryVariables
  >(JournalEntryCountsDocument, baseOptions);
}
export type JournalEntryCountsQueryHookResult = ReturnType<
  typeof useJournalEntryCountsQuery
>;
export type JournalEntryCountsLazyQueryHookResult = ReturnType<
  typeof useJournalEntryCountsLazyQuery
>;
export type JournalEntryCountsQueryResult = Apollo.QueryResult<
  JournalEntryCountsQuery,
  JournalEntryCountsQueryVariables
>;
export const SkippedJournalEntryCountDocument = gql`
  query skippedJournalEntryCount($filter: JournalEntryFilter) {
    skippedJournalEntryCount(filter: $filter)
  }
`;

/**
 * __useSkippedJournalEntryCountQuery__
 *
 * To run a query within a React component, call `useSkippedJournalEntryCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkippedJournalEntryCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkippedJournalEntryCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSkippedJournalEntryCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SkippedJournalEntryCountQuery,
    SkippedJournalEntryCountQueryVariables
  >,
) {
  return Apollo.useQuery<
    SkippedJournalEntryCountQuery,
    SkippedJournalEntryCountQueryVariables
  >(SkippedJournalEntryCountDocument, baseOptions);
}
export function useSkippedJournalEntryCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SkippedJournalEntryCountQuery,
    SkippedJournalEntryCountQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    SkippedJournalEntryCountQuery,
    SkippedJournalEntryCountQueryVariables
  >(SkippedJournalEntryCountDocument, baseOptions);
}
export type SkippedJournalEntryCountQueryHookResult = ReturnType<
  typeof useSkippedJournalEntryCountQuery
>;
export type SkippedJournalEntryCountLazyQueryHookResult = ReturnType<
  typeof useSkippedJournalEntryCountLazyQuery
>;
export type SkippedJournalEntryCountQueryResult = Apollo.QueryResult<
  SkippedJournalEntryCountQuery,
  SkippedJournalEntryCountQueryVariables
>;
export const SkippedJournalEntryTableDataDocument = gql`
  query skippedJournalEntryTableData(
    $filter: JournalEntryFilter
    $sortOptions: JournalEntrySortOptions
    $paginationOptions: PaginationOptions
  ) {
    journalEntries: skippedJournalEntries(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...journalEntryTableDataItem
      }
    }
  }
  ${JournalEntryTableDataItemFragmentDoc}
`;

/**
 * __useSkippedJournalEntryTableDataQuery__
 *
 * To run a query within a React component, call `useSkippedJournalEntryTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkippedJournalEntryTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkippedJournalEntryTableDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useSkippedJournalEntryTableDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SkippedJournalEntryTableDataQuery,
    SkippedJournalEntryTableDataQueryVariables
  >,
) {
  return Apollo.useQuery<
    SkippedJournalEntryTableDataQuery,
    SkippedJournalEntryTableDataQueryVariables
  >(SkippedJournalEntryTableDataDocument, baseOptions);
}
export function useSkippedJournalEntryTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SkippedJournalEntryTableDataQuery,
    SkippedJournalEntryTableDataQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    SkippedJournalEntryTableDataQuery,
    SkippedJournalEntryTableDataQueryVariables
  >(SkippedJournalEntryTableDataDocument, baseOptions);
}
export type SkippedJournalEntryTableDataQueryHookResult = ReturnType<
  typeof useSkippedJournalEntryTableDataQuery
>;
export type SkippedJournalEntryTableDataLazyQueryHookResult = ReturnType<
  typeof useSkippedJournalEntryTableDataLazyQuery
>;
export type SkippedJournalEntryTableDataQueryResult = Apollo.QueryResult<
  SkippedJournalEntryTableDataQuery,
  SkippedJournalEntryTableDataQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
