import DataTable from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { IntegerInput } from 'components/Form/IntegerInput';
import { PlainText } from 'components/Form/PlainText';
import { MonthlyChecklistItemActionSelect } from 'components/Lookups/MonthlyChecklistItemActionLookups/MonthlyChecklistItemActionSelect';
import { standardFormatDate } from 'jec-common/lib/utils/date-utils';
import React from 'react';
import {
  MonthlyChecklistItemActionEnum,
  MonthlyChecklistItemViewFragment,
  MonthlyChecklistItemViewSortBy,
  SortDirection,
  UpdateMonthlyChecklistItemInput,
} from 'types/graphql';
import { MonthlyChecklistItemComment } from 'components/MonthlyChecklistItemComment';

interface IProps {
  items: MonthlyChecklistItemViewFragment[];
  onItemChanged: (input: UpdateMonthlyChecklistItemInput) => Promise<void>;
  onItemUpdated: (input: UpdateMonthlyChecklistItemInput) => Promise<void>;
  onSaveComment: (
    monthlyChecklistItemId: number,
    comment: string | undefined,
  ) => Promise<boolean>;
  onItemAdded: (masterChecklistItemId: number) => Promise<void>;
  sort: {
    sortBy?: MonthlyChecklistItemViewSortBy;
    sortDirection?: SortDirection;
  };
  onSort: (
    sortBy: MonthlyChecklistItemViewSortBy,
    sortDirection: SortDirection,
  ) => void;
  exportRef?: (
    fn: (args: {
      items: MonthlyChecklistItemViewFragment[];
      filename: string;
      onDone?: () => void;
    }) => Promise<void>,
  ) => void;
  readOnly?: boolean;
}

export const MonthlyChecklistItemTable = (props: IProps): JSX.Element => {
  const columns: IDataTableColumnDefinition<
    MonthlyChecklistItemViewFragment,
    MonthlyChecklistItemViewSortBy
  >[] = [
    {
      dataFieldName: 'action',
      heading: 'Action',
      width: '7rem',
      render: (item: MonthlyChecklistItemViewFragment) => (
        <MonthlyChecklistItemActionSelect
          value={item.monthlyChecklistItemAction}
          existingComment={item.comment ?? undefined}
          onSelect={(
            value?: MonthlyChecklistItemActionEnum,
            comment?: string,
          ) => {
            !!item.monthlyChecklistItemId
              ? props.onItemUpdated({
                  id: item.monthlyChecklistItemId,
                  action: value,
                  comment: comment,
                })
              : props.onItemAdded(item.masterChecklistItem.id);
          }}
          disabled={props.readOnly}
        />
      ),
    },
    {
      dataFieldName: 'comment',
      heading: '',
      width: '2rem',
      render: (item: MonthlyChecklistItemViewFragment) => (
        <div className="d-flex justify-content-center mt-2">
          {item.period?.closedDate || !item.monthlyChecklistItemId ? (
            <MonthlyChecklistItemComment
              monthlyChecklistItem={item}
              readonly={true}
              onCommentUpdated={props.onSaveComment}
            />
          ) : (
            <MonthlyChecklistItemComment
              monthlyChecklistItem={item}
              onCommentUpdated={props.onSaveComment}
            />
          )}
        </div>
      ),
      excelExport: (item: MonthlyChecklistItemViewFragment) => item.comment,
    },
    {
      dataFieldName: 'partOfPeriod',
      heading: 'Part of Scheduled Period',
      width: '10rem',
      render: (item: MonthlyChecklistItemViewFragment) => {
        return <PlainText>{item.fallsInPeriod ?? 'No'}</PlainText>;
      },
      excelExport: (item: MonthlyChecklistItemViewFragment) =>
        item.fallsInPeriod ?? 'No',
      sortBy: MonthlyChecklistItemViewSortBy.PartOfScheduledPeriod,
    },
    {
      dataFieldName: 'daysAfterClose',
      heading: 'Days After Close',
      headClassName: 'text-warning',
      width: '8rem',
      render: (item: MonthlyChecklistItemViewFragment) => {
        if (!!item.period?.closedDate) {
          return <PlainText>{item.dueDaysAfterClose.toString()}</PlainText>;
        }

        return (
          <IntegerInput
            value={item.dueDaysAfterClose}
            onChange={async (value) => {
              await props.onItemUpdated({
                id: item.monthlyChecklistItemId,
                dueDaysAfterClose: value,
              });
            }}
            readOnly={
              props.readOnly || item.monthlyChecklistItemAction !== 'Include'
            }
          />
        );
      },
      excelExport: (item: MonthlyChecklistItemViewFragment) =>
        item.dueDaysAfterClose,
      sortBy: MonthlyChecklistItemViewSortBy.DueDaysAfterClose,
    },
    {
      dataFieldName: 'numberOfOccurrences',
      heading: '# OCC',
      headClassName: 'text-warning',
      width: '6rem',
      render: (item: MonthlyChecklistItemViewFragment) => {
        if (!!item.period?.closedDate) {
          return <PlainText>{item.numberOfOccurrences.toString()}</PlainText>;
        }

        return (
          <IntegerInput
            value={item.numberOfOccurrences}
            onChange={async (value) => {
              await props.onItemUpdated({
                id: item.monthlyChecklistItemId,
                numberOfOccurrences: value,
              });
            }}
            readOnly={
              props.readOnly || item.monthlyChecklistItemAction !== 'Include'
            }
          />
        );
      },
      excelExport: (item: MonthlyChecklistItemViewFragment) =>
        item.numberOfOccurrences,
      sortBy: MonthlyChecklistItemViewSortBy.NumberOfOccurrences,
    },
    {
      dataFieldName: 'entity',
      heading: 'Entity',
      width: '7rem',
      render: (item: MonthlyChecklistItemViewFragment) => {
        return <PlainText>{item.kbsEntity.name}</PlainText>;
      },
      excelExport: (item: MonthlyChecklistItemViewFragment) =>
        item.kbsEntity.name,
      sortBy: MonthlyChecklistItemViewSortBy.Entity,
    },
    {
      dataFieldName: 'title',
      heading: 'Title',
      render: (item: MonthlyChecklistItemViewFragment) => (
        <PlainText>{item.title}</PlainText>
      ),
      excelExport: (item: MonthlyChecklistItemViewFragment) => item.title,
      sortBy: MonthlyChecklistItemViewSortBy.Title,
    },
    {
      dataFieldName: 'type',
      heading: 'Type Of Entry',
      width: '8rem',
      render: (item: MonthlyChecklistItemViewFragment) => (
        <PlainText>{item.jeType.name}</PlainText>
      ),
      excelExport: (item: MonthlyChecklistItemViewFragment) => item.jeType.name,
      sortBy: MonthlyChecklistItemViewSortBy.TypeOfEntry,
    },
    {
      dataFieldName: 'journalId',
      heading: 'Journal ID',
      width: '17rem',
      headClassName: 'text-warning',
      cellClassName: (item: MonthlyChecklistItemViewFragment | undefined) =>
        item?.wasAddedBySync ? 'bg-warning' : undefined,
      render: (item: MonthlyChecklistItemViewFragment) => (
        <PlainText>{item.journalId}</PlainText>
      ),
      excelExport: (item: MonthlyChecklistItemViewFragment) => item.journalId,
      sortBy: MonthlyChecklistItemViewSortBy.JournalId,
    },
    {
      dataFieldName: 'group',
      heading: 'Group',
      width: '8rem',
      render: (item: MonthlyChecklistItemViewFragment) => (
        <PlainText>{item.kbsGroup.name}</PlainText>
      ),
      excelExport: (item: MonthlyChecklistItemViewFragment) =>
        item.kbsGroup.name,
      sortBy: MonthlyChecklistItemViewSortBy.Group,
    },
    {
      dataFieldName: 'frequency',
      heading: 'Freq',
      width: '8rem',
      render: (item: MonthlyChecklistItemViewFragment) => (
        <PlainText>
          {item.jeFrequency.name}
          {item.jeFrequency.periodModulus === item.jeFrequencyPeriodOffset
            ? ''
            : ` (${item.jeFrequencyPeriodOffset})`}
        </PlainText>
      ),
      excelExport: (item: MonthlyChecklistItemViewFragment) =>
        `${item.jeFrequency.name}${
          item.jeFrequency.periodModulus === item.jeFrequencyPeriodOffset
            ? ''
            : ` (${item.jeFrequencyPeriodOffset})`
        }`,
      sortBy: MonthlyChecklistItemViewSortBy.Frequency,
    },
    {
      dataFieldName: 'responsible',
      heading: 'Responsible',
      width: '10rem',
      render: (item: MonthlyChecklistItemViewFragment) => (
        <PlainText>{item.responsibleUser.name}</PlainText>
      ),
      excelExport: (item: MonthlyChecklistItemViewFragment) =>
        item.responsibleUser.name,
      sortBy: MonthlyChecklistItemViewSortBy.Responsible,
    },
    {
      dataFieldName: 'lastExecutionDate',
      heading: 'Last Execution Date',
      width: '10rem',
      render: (item: MonthlyChecklistItemViewFragment) =>
        standardFormatDate(item.masterChecklistItem.lastExecutedDate),
      excelExport: (item: MonthlyChecklistItemViewFragment) =>
        standardFormatDate(item.masterChecklistItem.lastExecutedDate),
      sortBy: MonthlyChecklistItemViewSortBy.LastExecution,
    },
  ];

  return (
    <>
      <DataTable<
        MonthlyChecklistItemViewFragment,
        MonthlyChecklistItemViewSortBy
      >
        columns={columns}
        data={props.items}
        getDataItemId={(item: MonthlyChecklistItemViewFragment) =>
          item.monthlyChecklistItemId
        }
        small={false}
        exportRef={props.exportRef}
        sort={props.sort}
        onSort={props.onSort}
      ></DataTable>
    </>
  );
};

export default MonthlyChecklistItemTable;
